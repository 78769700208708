const home: any = {
	home: {
		en: 'home',
		de: 'home',
	},
	// Users: {
	// 	en: 'Users',
	// 	de: 'Benutzer',
	// },
	// Trainers: {
	// 	en: 'Trainers',
	// 	de: 'Trainer',
	// },

	maxTrainingGain: {
		en: 'Maximum training success',
		de: 'Maximaler Trainingserfolg',
	},
	with: { en: 'with', de: 'mit' },
	personalTrainer: { en: 'personal trainers', de: 'Personal Trainer' },
}

const translations: any = { en: {}, de: {} }
const allTranslations: any = { home }
for (const key in allTranslations) {
	translations['en'][key] = {}
	translations['de'][key] = {}
	for (const tr in allTranslations[key]) {
		translations['en'][key][tr] = allTranslations[key][tr]['en']
		translations['de'][key][tr] = allTranslations[key][tr]['de']
	}
}
export default translations
