const meta = {
	layout: 'AppLayoutDefault',
	navGroup: '',
	icon: 'ptp_admin',
	// allowedRoles: 'onlyTrainers',
	allowedRoles: 'allUser',
}

const moduleRoute = {
	path: '/location',
	name: 'LocationModul',
	component: () => import('./LocationModule.vue'),
	meta: { ...meta, isParent: true },
	children: [
		{
			path: '',
			name: 'Location',
			component: () => import('./views/LocationView.vue'),

			meta,
		},
		// {
		// 	path: '/dashboard/locations',
		// 	name: 'LocationAdminDashboard',
		// 	component: () => import('./views/LocationAdminDashboard.vue'),
		// 	meta: {
		// 		title: 'Locations',
		// 		layout: 'AppLayoutUser',
		// 		navGroup: 'account',
		// 		icon: 'ptp_admin',
		// 		// allowedRoles: 'onlyTrainers',
		// 		allowedRoles: 'onlyAdmins',
		// 	},
		// },
		// {
		//   path: 'list',
		//   name: 'LocationsList',
		//   component: () => import('./views/LocationStudiosNextToUserView.vue'),
		//   meta,
		// },
		{
			path: ':locationId',
			name: 'LocationId',
			component: () => import('./views/LocationView.vue'),
			meta,
		},
	],
}

export default moduleRoute
