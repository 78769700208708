// import routes from '@/router/routes'
import { roles, type RoleGroup, type Role } from '@/router/roles'
import router from '@/router/router'
import { keyBy } from '@/libs/utils'
import { useRouter } from 'vue-router'

const navigationRoutes: {
	trainee: string[]
	trainer: string[]
	admin: string[]
	developer: string[]
} = {
	// trainee: ['AppointmentDashboardView', 'AccountDashboard'],
	trainee: ['DashboardTraineeAppointments', 'AccountDashboard'],
	trainer: [
		'DashboardTrainerAppointments',
		'CalendarDashboardView',
		'AccountDashboard',
		// 'PaymentDashboardView',
	],
	admin: ['AdminTrainerView', 'AdminStudioView', 'AdminUsersView', 'AppointmentDashboardView', 'AccountDashboard'],
	developer: ['AccountDashboard'],
}

const makeDashboardRoutes = (routes: any = null): any => {
	const result: any = {}
	const routesByName = keyBy(routes || router.getRoutes(), 'name')
	for (const roleKey in navigationRoutes) {
		const role: Role = roleKey as Role
		if (role) {
			if (!result[role]) result[role] = {}
			const navRoutes = navigationRoutes[role]
			for (const routeName of navRoutes) {
				const el = routesByName[routeName]
				if (el) {
					const meta = el.meta
					const navGroup = meta.navGroup || ''
					if (navGroup) {
						const isParent = meta.isParent || false
						const allowedRoles: RoleGroup = el.meta.allowedRoles || 'allUser'
						if (!result[role][navGroup]) result[role][navGroup] = []
						if (!isParent && roles[allowedRoles]?.includes(role)) result[role][navGroup].push(el)
					}
				}
			}
		}
		for (const role in result) {
			for (const navGroup in result[role]) {
				if (result[role][navGroup].length == 0) delete result[role][navGroup]
			}
		}
	}
	console.log('routesByName', routesByName)
	return result
}

// const dashboardRoutes = makeDashboardRoutes()

// export { navigationRoutes, dashboardRoutes, makeDashboardRoutes }
export { navigationRoutes, makeDashboardRoutes }
