<template lang="pug">
#mainMapBox
</template>

<script setup lang="ts">
import { onMounted, ref, watch, computed } from 'vue'
import type { Ref } from 'vue'
import mapboxgl, { type LngLatLike } from 'mapbox-gl'
import { Map, Marker, Popup } from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { useUserStore } from '@/stores'
import { waitFor } from '@/libs/utils'

const userStore = useUserStore()
const mapBoxKey = import.meta.env.VITE_MAPBOX_KEY

let map: Map | null = null
let allMarker: any[] = []

const props = defineProps({
	locations: { type: Array, default: () => [] },
})

const gyms: string[] = ['mcfit', 'goldsgym', 'johnreed']
const isMapSet = ref(false)
const isMyMarkerSet = ref(false)

onMounted(() => {
	console.log('onMounted mapbox')
	setTimeout(async () => {
		await makeMapBox()
		await makeMapMaker()
	}, 50) //wait for user coords
})

const myMarker: Ref<Marker | null> = ref(null)

const setMyMarker = async () => {
	console.log('setMyMarker', userStore.userCoords.coords)
	console.log('setMyMarker isMyMarkerSet.value', userStore.userCoords.coords)
	if (userStore.userCoords.coords) {
		const coords: LngLatLike = JSON.parse(JSON.stringify(userStore.userCoords.coords))
		myMarker.value?.remove()
		if (map && !isMyMarkerSet.value && coords) {
			const el = document.createElement('div')
			el.className = 'marker my-marker myself'
			el.draggable = true
			if (userStore.userCoords.showMarker) el.className = 'marker my-marker myself myself-large'
			myMarker.value = await new Marker({ draggable: true })
				.setLngLat(coords)
				.setPopup(new Popup({ offset: 25 }).setHTML('thats you :P'))

			myMarker.value.addTo(map)
			isMyMarkerSet.value = true
		}
	}
}

const makeMapBox = async () => {
	mapboxgl.accessToken = mapBoxKey
	// const style = 'mapbox://styles/propellercat/ckr4kj4g90jl817nzqsf0fc67'
	// const style = 'mapbox://styles/mapbox/satellite-streets-v11'
	const style = 'mapbox://styles/propellercat/cladmvszr000014o36odnforo'
	// const style = 'mapbox://styles/propellercat/cladmxn23000714plauxea73u'
	isMapSet.value = false
	if (!map) {
		let center: LngLatLike = [-30, 60] //: [-30, 60], //world, or // [13.824, 48.781] for europa,
		let zoom = 1
		let pitch = 30
		if (userStore.userCoords.isSet) {
			center = userStore.userCoords.coords
			zoom = 10
			pitch = 40
		}

		map = await new mapboxgl.Map({
			container: 'mainMapBox',
			style,
			center,
			zoom,
			pitch,
			// projection: 'globe',
			projection: { name: 'globe' },
			// { name: "globe" | "albers" | "equalEarth" | "equirectangular" | "lambertConformalConic" | "mercator" | "naturalEarth" | "winkelTripel"; center?: [number, number] | undefined; parallels?: [...] | undefined; }
		})

		map.on('wheel', (event) => {
			if (event.originalEvent.ctrlKey) return
			if (event.originalEvent.metaKey) return
			if (event.originalEvent.altKey) return
			event.preventDefault()
		})

		await map.on('style.load', () => {
			if (map) map.setFog({})
		})
	}
	isMapSet.value = true
}

const locMarkers = computed(() => {
	return {
		features: [
			{
				type: 'Feature',
				properties: {
					id: 'ak16994521',
					mag: 2.3,
					time: 1507425650893,
					felt: null,
					tsunami: 0,
				},
				geometry: { type: 'Point', coordinates: [16.5129, 46.1016, 0.0] },
			},
			{
				type: 'Feature',
				properties: {
					id: 'ak16994519',
					mag: 1.7,
					time: 1507425289659,
					felt: null,
					tsunami: 0,
				},
				geometry: { type: 'Point', coordinates: [16.4048, 46.1224, 4.5] },
			},
		],
	}
})

const makeMapMaker = async () => {
	await waitFor(200)
	if (map) {
		// map.getSource('studios').setData(locMarkers.value)

		allMarker.forEach((el: any) => el.remove())
		allMarker = []
		for (const locDataOb of props.locations) {
			const locData: any = locDataOb
			// console.log('locData', locData)
			const c = locData.coordinates
			const coords = [c[1], c[0]] // coords have lng/lat

			const icon = locData.icon || 'gray'
			const loc: any = locData
			const random: number = Math.floor(Math.random() * gyms.length)
			// const gym: string = gyms[random]

			const marker: any = {
				type: 'Feature',
				geometry: {
					type: 'Point',
					// coordinates: [geoCoords._longitude, geoCoords._latitude],
					coordinates: coords,
				},
				properties: {
					title: 'el.name',
					description: '',
					// state: 'unknown',
					// state: gym,
					icon,
					status: 'el.state',
					content: `
					<img src="/logos/${loc.icon}.svg" width="64" height="64" alt="gym-logo">
				
					<h3>${loc.name} </h3>
					<p>${loc.street}</p>
					<p>${loc.postalCode} ${loc.city}</p>
					<a class="internal-link m-2 btn btn-xs" rel="noopener" target="_self" href="/location?id=${loc.id}"> Zu den Trainern</a>
					`,
					// <a class="internal-link m-2 btn btn-xs" rel="noopener" target="_self" href="location/${loc.id}"> Zu den Trainern</a>
				},
			}

			const el = document.createElement('div')
			el.className = `marker ${marker.properties.icon}`
			// el.className = `marker ${marker.properties.state}`
			// el.className = `marker ${marker.properties.state}`
			const mmarker: Marker = new Marker(el)
			mmarker
				.setLngLat(marker.geometry.coordinates)
				.setPopup(new Popup({ offset: 25 }).setHTML(marker.properties.content))
				.addTo(map)

			allMarker.push(mmarker)
		}
		// setMyMarker()
	}
}

const mapFlyToMyLocation = async () => {
	if (map) {
		map.flyTo({
			...{
				center: userStore.userCoords.coords || [16.376, 48.182],
				zoom: 11,
				pitch: 30,
			},
			duration: userStore.userCoords.isSet ? 1200 : 4000,
			// duration: 2000,
			essential: true,
		})
	}
}

const flyToFirst = async (loc: any) => {
	if (map && loc.coordinates) {
		// console.log(map.getCenter());
		// console.log(loc);
		map.flyTo({
			...{
				center: [loc.coordinates[1], loc.coordinates[0]],
				zoom: 11,
				pitch: 30,
			},
			duration: 1600,
			essential: true,
		})
	}
}

const initMyLocation = async () => {
	if (isMapSet.value) {
		if (userStore.userCoords.isSet) mapFlyToMyLocation()
		await setMyMarker()
	}
}
//! WATCHER

const immediate = true
const deep = true
watch(() => userStore.userCoords, initMyLocation, { immediate, deep })
watch(() => isMapSet, initMyLocation, { immediate, deep })
watch(
	() => props.locations,
	(locations: any[]) => {
		makeMapMaker()
		flyToFirst(locations[0])
	},
)
</script>

<style scoped lang="scss">
#mainMapBox {
	width: 100%;
	// height: 100%
	// height: 800px;
	height: 500px;
	min-height: 400px;
	min-width: 400px;
}
</style>
