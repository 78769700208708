import { ref, computed, type ComputedRef } from 'vue'
import type { Ref } from 'vue'
import { defineStore } from 'pinia'
import type { StoreDefinition } from 'pinia'
import http from '@/libs/http'
import { useStorage } from '@vueuse/core'
import { keyBy, now } from '@/libs/utils'
import { useRouter } from 'vue-router'

type PaymentMetaData = {
	appointmentId: string
	trainerId: string
}

export const usePaymentStore: StoreDefinition = defineStore('payment', () => {
	//! state
	const payments: Ref<any> = ref({})
	const paymentList: ComputedRef<any> = computed(() => Object.values(payments.value))
	const paymentsStripe: Ref<any> = ref({})
	const paymentsCash: Ref<any> = useStorage('paymentsCash', {})
	const modified = useStorage(`modified`, { payments: 0 })
	// const paymentIntent = useStorage('paymentIntent', {})
	const paymentsByAptId = computed(() => {
		const result: any = {}
		for (const payIdx in paymentsStripe.value) {
			const pay = paymentsStripe.value[payIdx]
			result[pay.metadata.appointment_id] = pay
		}
		for (const payIdx in paymentsCash.value) {
			const pay: any = paymentsCash.value[payIdx]
			result[pay.appointmentId] = pay
		}
		return result
	})

	const createStripePayment = async (data: PaymentMetaData) => {
		console.log('createStripePayment')
		// todo:
		const res = await http.post(`/payment/stripe/create`, data)
		return res.data
	}

	// const createCashPayment = async (data: PaymentMetaData) => {
	const createCashPayment = async (_payData: PaymentMetaData[]) => {
		try {
			console.log('createCashPayment', _payData)
			const res = await http.postAuth(`/payment/cash/create`, _payData)
			return res.data
		} catch (error: any) {
			throw new Error(error.response?.data?.message || error.message)
		}
	}

	const fetchPaymentList = async () => {
		const res = await http.getAuth(`payment/listall`, { headers: { modified: modified.value.payments } })
		const data = res.data.data
		modified.value.payments = now() - 10
		payments.value = keyBy(data.stripe, 'id')
		paymentsStripe.value = keyBy(data.stripe, 'id')
		paymentsCash.value = { ...paymentsCash.value, ...data.cash }
		return
	}

	const fetchPaymentById = async (_paymentIntentId: string) => {
		console.log('fetchPaymentById')
		const res = await http.getAuth(`payment/secret/${_paymentIntentId}`)
		return res.data
	}

	const vatPercent: Ref<number> = computed(() => 20)
	const calcVat = (sum: number) => sum * (1 - 1 / (1 + vatPercent.value / 100))

	return {
		payments,
		paymentsStripe,
		paymentsCash,
		paymentList,
		paymentsByAptId,
		// paymentIntent,
		vatPercent,
		createStripePayment,
		createCashPayment,
		fetchPaymentList,
		fetchPaymentById,
		calcVat,
	}
})
