<script setup lang="ts">
// import { ref, shallowRef, toRef, watch, computed } from 'vue'
import { computed, toRef, watch } from 'vue'
import { useField } from 'vee-validate'
// import { watchDebounced } from '@vueuse/core'
const emit = defineEmits(['update:modelValue'])

const props = defineProps({
	type: { type: String, default: 'text' },
	value: { type: String, default: '' },
	name: { type: String, required: true },
	label: { type: String, required: true },
	successMessage: { type: String, default: '' },
	placeholder: { type: String, default: '' },
	modelValue: { type: String, default: '' },
})

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name')
const {
	value: inputValue,
	errorMessage,
	handleBlur,
	handleChange,
	meta,
} = useField(name, undefined, {
	initialValue: props.value,
})

watch(inputValue, (nv: string) => {
	console.log('inputValue', nv)
	emit('update:modelValue', nv)
})
</script>

<template>
	<div class="ptp-input" :class="{ 'has-error': !!errorMessage, 'has-success': meta.valid }">
		<label v-if="label" :for="name" class="label pb-0 uppercase">
			<span class="label-text">{{ label }}</span>
		</label>
		<select
			:value="inputValue"
			class="select w-full max-w-xl input-bordered"
			:name="name"
			:id="name"
			@input="handleChange"
			@blur="handleBlur"
		>
			<slot></slot>
		</select>
		<!-- <input class="input input-bordered w-full max-w-md" :name="name" :id="name" :type="type" :value="inputValue" -->
		<!-- :placeholder="placeholder" @input="handleChange" @blur="handleBlur" /> -->
		<span class="help-message" v-if="errorMessage || meta.valid">
			<span v-if="errorMessage || meta.valid">
				{{ errorMessage || successMessage }}
			</span>
		</span>
		<!-- <div class="alert alert-error shadow-lg" v-show="(errorMessage || meta.valid)">
			<div>
				<svg xmlns="http://www.w3.org/2000/svg" class="stroke-current flex-shrink-0 h-6 w-6" fill="none"
					viewBox="0 0 24 24">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
						d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
				</svg>
				<span>Error! Task failed successfully.</span>
			</div>
		</div> -->
	</div>
</template>

<style scoped lang="scss">
.ptp-input {
	@apply relative mb-1 w-full;
}

.help-message {
	// @apply rounded-lg w-96 text-sm h-4;
	@apply rounded-lg w-96 text-sm;
}

.ptp-input {
	&.has-error {
		input {
			&:focus {
			}
		}

		.help-message {
			@apply text-error;
		}
	}

	&.has-success {
		input {
			&:focus {
			}
		}

		.help-message {
			@apply text-success;
		}
	}
}
</style>
