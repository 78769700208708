const moduleRoute = {
	path: '/account',
	name: 'AccountModul',
	component: () => import('../AccountModule.vue'),
	meta: {
		layout: 'AppLayoutUser',
		navGroup: 'account',
		icon: 'ptp_user',
		allowedRoles: 'onlySignedIn',
		title: 'Account',
		isParent: true,
	},
	children: [
		{
			path: '/dashboard/account',
			name: 'AccountDashboard',
			// component: () => AccountView,
			component: () => import('../views/AccountView.vue'),
			meta: {
				title: 'Account',
				layout: 'AppLayoutUser',
				navGroup: 'account',
				icon: 'ptp_user',
				allowedRoles: 'onlySignedIn',
			},
		},
		{
			path: '/signin',
			name: 'AccountSignIn',
			// component: () => AccountLoginView,
			component: () => import('../views/AccountLoginView.vue'),

			meta: {
				redirect: '/dashboard/account',
				layout: 'AppLayoutDefault',
				navGroup: 'account',
				icon: 'ptp_user',
				allowedRoles: 'notSignedIn',
			},
			// meta: { ...meta, allowedRoles: 'allUser' },
		},
	],
}
export default moduleRoute
// export { routes: moduleRoute }
// export moduleRoute
