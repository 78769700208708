<template lang="pug">

.dropdown.dropdown-end(v-if="cartStore")
  label.btn.btn-ghost.btn-circle(tabindex="0")
    .indicator
      svg.h-5.w-5(xmlns="http://www.w3.org/2000/svg", fill="none", viewBox="0 0 24 24", stroke="currentColor")
        path(stroke-linecap="round", stroke-linejoin="round", stroke-width="2", d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z")
      span.badge.badge-info.badge-sm.indicator-item(v-if="cartStore.count") {{cartStore.count}}
  .mt-3.card.card-compact.dropdown-content.w-52.bg-base-200.shadow(tabindex="0")
    .card-body
      span.font-bold.text-lg(v-if="cartStore.count") {{$t('appointments')}}
      span.font-bold.text-lg(v-else) {{$t('noAppointments')}}
      span.text-info(v-if="cartStore?.count") {{$t('subtotal')}}: {{currency.format(cartStore.sum/100)}}
      .card-actions(v-if="cartStore?.count")
        RouterLink.btn.btn-secondary.text-white.btn-block(to="/cart") {{$t('apt.viewAppointments')}}
        RouterLink.btn.btn-secondary.text-white.btn-block(to="/checkout") {{$t('checkout')}}

</template>

<script setup lang="ts">
import { currency } from '@/libs/utils'
import { useCartStore } from '../store'

const cartStore = useCartStore()
</script>
