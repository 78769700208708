import { now } from '@/libs/utils'
// import dayjs from 'dayjs'

const inOneDay = 60 * 60 * 24 * 1
const sixHours = 60 * 60 * 6
const oneHour = 60 * 60 * 1
const justNow = 0
const beforeOneHour = -60 * 60 * 1
const beforeTwoHours = -60 * 60 * 2
const nowTime = now()
const timeClass = (ts: number) => {
	if (!ts) return ''
	const timeLeft = ts - nowTime
	let state = ''
	if (timeLeft > inOneDay) state = 'isChangeable'
	else if (timeLeft > sixHours) state = 'isFixed'
	else if (timeLeft > justNow) state = 'isComing'
	else if (timeLeft < justNow && timeLeft > beforeOneHour) state = 'isOngoing'
	else if (timeLeft < beforeOneHour && timeLeft > beforeTwoHours) state = 'isJustOver'
	else if (timeLeft < beforeTwoHours) state = 'isOver'
	// console.log('timeLeft', ts, timeLeft, state);
	return state
}

export { timeClass }
