import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'

import VueFinalModal from 'vue-final-modal'
import Toast from 'vue-toastification'
import type { PluginOptions } from 'vue-toastification'

// own modules

import { Firebase } from './libs/Firebase'
import Router from './router'
// import router from './router/router'

import 'vue-toastification/dist/index.css'
import './assets/main.sass'
import './assets/icons/rendered/icons.scss'

// @ts-ignore
import vClickOutside from 'click-outside-vue3'

import { useAuthStore } from './stores'
// import * as stores from './stores'
// stores.globalStore

import GlobalComponents from './libs/globals'
import i18n from '@/i18n'

import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import duration from 'dayjs/plugin/duration'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
// import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekday from 'dayjs/plugin/weekday'
import 'dayjs/locale/de'

const currentLanguage = localStorage.lang || navigator.language.substring(0, 2) || 'en'
// import weekday from 'dayjs/plugin/weekday'

// import * as dayjsLocaleDe from 'dayjs/locale/de'
// require('dayjs/locale/es')

// dayjs.extend(weekOfYear)
dayjs.extend(relativeTime)
dayjs.extend(weekday)
dayjs.extend(isoWeek)
dayjs.extend(duration)
dayjs.extend(timezone)
dayjs.locale(currentLanguage)
// dayjs.locale("en")
// PluginOptions.position

const toastOptions: PluginOptions | any = {
	//todo typings
	timeout: 7000,
	// position: "bottom-center",
	position: 'top-center',
	closeOnClick: false,
	pauseOnFocusLoss: true,
	pauseOnHover: true,
	draggable: true,
	draggablePercent: 0.6,
	showCloseButtonOnHover: true,
}

const bootstrap = async () => {
	new Firebase()
	const app = createApp(App)
		.use(GlobalComponents)
		.use(i18n)
		.use(createPinia())
		.use(VueFinalModal as any)
		.use(Toast, toastOptions)
		.use(vClickOutside)
	// .use(router)
	// .component('VModal', VModal)
	await useAuthStore().init()
	await useAuthStore().loadUserData()

	// app.use(router) // init after firebase & fb-user for role-restricted-routes
	app.use(Router) // init after user firebse & fb-user
	// app.use(GlobalComponents)
	app.config.performance = true
	// app.config.globalProperties.$axios = 'axios'
	console.log(app)
	// app.use(router)
	app.mount('#app')
}
bootstrap()

// app.config.errorHandler = (error: unknown, vm: unknown, info: unknown) => {
//   console.error(error)
//   console.log(vm)
//   console.log(info)
// }
