<template lang="pug">
.relative.w-96
	VTextInput.w-96(
		v-model="search",
		name="Search"
		label=""
		placeholder="Search")

	.absolute.ptp-spinner.ptp-spinner-sm.top-3.right-3(v-if="loading")

template(v-if="search && !loading")
	ul(tabindex="0").dropdown-content.menu.menu-compact.p-2.shadow.rounded-box.w-full.backdrop-blur-sm(class="bg-base-200/80")
		template(v-if="results.length==0 && search && !loading")
			li
				span no results
		template(v-else)
			slot(:results="results")

</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import axios from 'axios'
import type { Axios } from 'axios'
import ts from '@/libs/typesense'
const emit = defineEmits(['results', 'search'])
const props = defineProps({
	params: { type: Object, required: true },
	collection: { type: String, required: true },
	immediate: { type: Boolean, default: false },
})

const search = ref('')
const results: any = ref({})
const loading = ref(false)

let timer: any = null

watch(
	search,
	(nv: string) => {
		if (timer) clearTimeout(timer)
		if (nv.length > 0 || props.immediate) {
			loading.value = true
			setTimeout(async () => {
				emit('search', nv)
			}, 100)
			timer = setTimeout(async () => {
				searchTypesense()
			}, 300)
		} else {
			loading.value = false
			results.value = []
			emit('results', [])
		}
	},
	{ immediate: props.immediate },
)

const searchTypesense = async () => {
	try {
		console.log('searchTypesense')
		loading.value = true
		results.value = []

		//? #1

		const params = {
			q: search.value,
			per_page: 16,
			// params: {
			// per_page: 32,
			...props.params,
			// }
		}
		// const res = await ts.search(props.collection, params)
		const res = await ts.docs(props.collection, params)

		results.value = res
		emit('results', res)

		//? #2
		// results.value = []
		// emit('results', [])
		// const res = await axios.get(
		// `${import.meta.env.VITE_TYPESENSE_BASE_URL}/collections/${props.collection
		// }/documents/search`,
		// {
		//	params: {
		//		q: search.value,
		//		'x-typesense-api-key': import.meta.env.VITE_TYPESENSE_API_SEARCH_KEY,
		//		per_page: 32,
		//		...props.params,
		//	},
		// },
		// )
		// results.value = res.data.hits
		// emit('results', res.data.hits)
	} catch (error: any) {
		console.log(error.message)
		results.value = {}
	} finally {
		loading.value = false
	}
}
</script>

<style lang="scss"></style>
