const unknownErrorMessateEN =
	"Oh well, thats embarrassing. Something went wrong. We're sorry. Please try again later or contact our service team."
const unknownErrorMessateDE =
	'Oh je, das ist uns jetzt peinlich. Tut uns Leid, aber da ist was schief gegangen. Bitte später nochmal versuchen oder Service kontaktieren.'

const validator: any = {
	FirstnameRequired: {
		en: 'Your first name ist required',
		de: 'Bitte Vornamen eingeben',
	},
	LastnameRequired: {
		en: 'Your last name ist required',
		de: 'Bitte Nachnamen eingeben',
	},
	CountryRequired: {
		en: 'Your country ist required',
		de: 'Bitte Land eingeben',
	},
	PostalcodeRequired: {
		en: 'Your postalCode ist required',
		de: 'Bitte PLZ eingeben',
	},
	CityRequired: {
		en: 'Your city ist required',
		de: 'Bitte Stadt eingeben',
	},
	StreetRequired: {
		en: 'Your street ist required',
		de: 'Bitte Strasse eingeben',
	},
	BirthdateRequired: {
		en: 'Your birthDate ist required',
		de: 'Bitte Geburtsdatum eingeben',
	},
	PhoneRequired: {
		en: 'Your phone ist required',
		de: 'Bitte Telefonnummer eingeben',
	},
	AcceptTermsRequired: {
		en: 'Please accept our terms!',
		de: 'Bitte die AGB bestätigen!',
	},
	// return '$T Please accept!'
	EmailRequired: {
		en: 'A valid email is required',
		de: 'Bitte eine valide Email eingeben',
	},
	// return '$T A valid email is required'
	PasswordRequired: {
		en: 'Your password is missing!',
		de: 'Passwort fehlt!',
	},
	// return '$T Your password?'
	PasswordRules: {
		en: 'Password must be between 6 and 128 characters long',
		de: 'Passwort muss zwischen 6 and 128 Zeichen lang sein',
	},
}

const menu: any = {
	Appointments: {
		en: 'Appointments',
		de: 'Termine',
	},
	DashboardAppointments: {
		en: 'Appointments',
		de: 'Termine',
	},
	DashboardTrainerAppointments: {
		en: 'Appointments',
		de: 'Termine',
	},
	DashboardTraineeAppointments: {
		en: 'Appointments',
		de: 'Termine',
	},
	Calendar: {
		en: 'Calendar',
		de: 'Kalender',
	},
	Account: {
		en: 'Account',
		de: 'Benutzer',
	},
	Trainers: {
		en: 'Alle Trainer',
		de: 'Benutzer',
	},
	Users: {
		en: 'Alle User',
		de: 'Benutzer',
	},
	Locations: {
		en: 'Alle Locations',
		de: 'Alle Locations',
	},
	AdminStudioView: {
		en: 'Fitness studios',
		de: 'Fitness Studios',
	},
	AdminUsersView: {
		en: 'Users',
		de: 'Benutzer',
	},
	AdminTrainerView: {
		en: 'Trainer',
		de: 'Trainer',
	},
}

const loc: any = {
	studiosNextToYou: {
		en: 'Studios next to you',
		de: 'Studios in deiner Nähe',
	},
}

const apt: any = {
	today: {
		en: 'today',
		de: 'heute',
	},
	myappointments: {
		en: 'My appointments',
		de: 'Meine Termine',
	},
	viewAppointments: {
		en: 'appointments',
		de: 'Termine',
	},
	calendar: {
		en: 'Calendar',
		de: 'Kalender',
	},
	chooseTime: {
		en: 'Time',
		de: 'Zeit',
	},
	chooseDateFirst: {
		en: 'Please choose date first',
		de: 'Bitte Datum wählen',
	},
	upcomingEvents: {
		en: 'upcoming appointments',
		de: 'kommende Termine',
	},
	pastEvents: {
		en: 'past appointments',
		de: 'vergangene Termine',
	},
	chooseYourTrainer: {
		en: 'Choose your trainer',
		de: 'Wähle deinen Trainer',
	},
	chooseYourDate: {
		en: 'Choose your date',
		de: 'Wähle deinen Termin',
	},
	location: {
		en: 'Location',
		de: 'Ort',
	},
	openingTimes: {
		en: 'Opening times',
		de: 'Öffnungszeiten',
	},
	personalTrainer: {
		en: 'Personal trainer',
		de: 'Personal Trainer',
	},
	session: {
		en: 'session',
		de: 'Stunde',
	},
}

const purchase: any = {
	ThankYou: {
		en: 'Thank you :)',
		de: 'Danke :)',
	},
	bookedSuccessfully: {
		en: 'You successfully booked an appointment.',
		de: 'Du hast erfolgreich einen Termin gebucht.',
	},
	payYourTrainer: {
		en: "Please don't forget to pay after your personal training.",
		de: 'Bitte vergiss nicht, nach dem Training deinen Trainer zu bezahlen.',
	},
}

const errors: any = {
	// 'Firebase: Error (auth/internal-error).': {
	NettworkError: {
		en: 'Please check your internet connection.',
		de: 'Bitte Internetverbindung prüfen.',
	},
	CheckEmailPassword: {
		en: 'Please check email/password.',
		de: 'Bitte Email/Password prüfen.',
	},
	CheckLaterAgain: {
		en: 'Unknown Error, please check again later.',
		de: 'Unbekannter Fehler, bitte später nochnmal versuchen.',
	},
	NoUserFound: {
		en: 'User not found.',
		de: 'Benutzer nicht gefunden.',
	},
	'file-too-large': {
		en: 'File too large, max 4MB.',
		de: 'Datei zu groß, max. 4MB.',
	},
	UnableToUploadImage: { en: unknownErrorMessateEN, de: unknownErrorMessateDE },

	NoValidDateTime: {
		en: 'Date or Time not valid!',
		de: 'Datum oder Zeit nicht gültig!',
	},
	AppointmentDateTimeAlreadyOver: {
		en: 'Appointment only in next month possible.',
		de: 'Termine nur innerhalb des nächsten Monats möglich.',
	},
	ErrorAptCreationAtLeastSixHoursBeforeDate: {
		en: 'Appointments can be booked at least 6 hours in advance.',
		de: 'Termine können mindestens 6 Stunden im Voraus gebucht werden.',
	},
	ErrorAptCreationCurrentlyReserved: {
		en: 'Appointment is currenlty reserved.',
		de: 'Termin ist derzeit reserviert.',
	},
	ErrorUpdatingUser: {
		en: 'User data not updated!',
		de: 'Benutzer Daten wurden nicht aktualisiert!',
	},
	ErrorSwitchingRole: {
		en: 'User role not changed!',
		de: 'Benutzer Rolle wurde nicht geändert!',
	},
}

const general: any = {
	//! no need to use 'general.${string}'
	en: {
		noAppointments: 'No appointments',
		appointments: 'Appointments',
		subtotal: 'Subtotal',
		sure: 'Sure?',
		delete: 'Delete',
		confirm: 'confirm',
		save: 'Save',
		replace: 'Replace',
		general: 'General',
		name: 'Name',
		Yes: 'Yes',
		Confirm: 'Confirm',
		OK: 'OK',
		CANCEL: 'Cancel',
		DropHere: 'Drop here',
		studiosNextToYou: 'Studios next to you',
		gender: 'Gender',
		trainerType: 'Trainer Type',
		rating: 'Rating',
		logout: 'Logout',
		login: 'Login',
	},
	de: {
		appointments: 'Termine',
		noAppointments: 'Keine Termine',
		subtotal: 'Zwischensumme',
		sure: 'Sicher?',
		delete: 'Löschen',
		confirm: 'bestätigen',
		save: 'Speichern',
		replace: 'Ersetzen',
		general: 'Allgemein',
		name: 'Name',
		Yes: 'Ja',
		Confirm: 'Bestätigen',
		OK: 'OK',
		CANCEL: 'Abbrechen',
		DropHere: 'Hier ablegen',
		studiosNextToYou: 'Studios in deienr Nähe',
		gender: 'Geschlecht',
		trainerType: 'Trainer Typ',
		rating: 'Bewertung',
		logout: 'Logout',
		login: 'Login',
	},
}

const translations: any = {
	en: {
		// ...{ errors: errors.en },
		...general.en,
	},
	de: {
		// ...{ errors: errors.de },
		...general.de,
	},
}

const allTranslations: any = {
	errors,
	validator,
	apt,
	loc,
	menu,
	purchase,
}

for (const key in allTranslations) {
	translations['en'][key] = {}
	translations['de'][key] = {}
	for (const tr in allTranslations[key]) {
		translations['en'][key][tr] = allTranslations[key][tr]['en']
		translations['de'][key][tr] = allTranslations[key][tr]['de']
	}
}
console.log(translations)

export default translations
