const account: any = {
	Login: {
		en: 'Login',
		de: 'Login',
	},
	Register: {
		en: 'Register',
		de: 'Registrieren',
	},
	ForgotPassword: {
		en: 'Forgot Password?',
		de: 'Password vergessen?',
	},
	SendEmail: {
		en: 'Send Email',
		de: 'Email Abschicken',
	},
	ForgotPasswordLinkSent: {
		en: 'A reset-password link has been sent to your email.',
		de: 'Ein Password-Reset Link wurde an Deine Email gesendet.',
	},
	userUpdated: {
		en: 'User updated',
		de: 'Daten wurden aktualisiert',
	},
	imageUploaded: {
		en: 'Image uploaded.',
		de: 'Bild hochgeladen.',
	},
}

const translations: any = { en: {}, de: {} }
const allTranslations: any = { account }
for (const key in allTranslations) {
	translations['en'][key] = {}
	translations['de'][key] = {}
	for (const tr in allTranslations[key]) {
		translations['en'][key][tr] = allTranslations[key][tr]['en']
		translations['de'][key][tr] = allTranslations[key][tr]['de']
	}
}
export default translations
