<template lang="pug">
.poComponent(ref="__comp", ispopover)
	div
		//- pre {{hvStyle}}
		//- button.btn._dropdown(@click="togglePopover")
		div(@click="showPopover")
			slot

		transition(name="fade")
			//- :class="`${hPos} ${vPos}`",
			.__popover.p-0.fixed(
				ref="__popover",
				:style="hvStyle",
				v-if="isOpen",
				@click="togglePopover",
				v-click-outside="clickOutside"
			)
				ul.p-1.menu.menu-compact.gap-1.dropdown-content.w-32
					slot(name="popover")

</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'

const props = defineProps({ forceTop: { type: Boolean, default: false } })

const isOpen = ref(false)
const hPos = ref('_right')
const vPos = ref('')
const topStyle = ref(0)
const leftStyle = ref(0)
const __comp: any = ref(null)
const __popover: any = ref(null)

const hvStyle = computed(() => `top:${topStyle.value}px;left:${leftStyle.value}px`)

watch(isOpen, (nv) => {
	if (nv) addHandler()
	else removeHandler()
})

const showPopover = async (e: any) => {
	isOpen.value = true
	// const bounds = this.$refs.poComponent.getBoundingClientRect()
	if (__comp.value) {
		const bounds = __comp.value.getBoundingClientRect()
		setTimeout(() => {
			// console.log(this.$refs);
			// const bounds2 = this.$refs.__popover.getBoundingClientRect()
			if (__popover.value) {
				const bounds2 = __popover.value.getBoundingClientRect()
				console.log(bounds2)
				const left = bounds.left
				const bottom = bounds.bottom
				const width = window.innerWidth
				const height = window.innerHeight

				topStyle.value = bounds.top + bounds.height
				leftStyle.value = bounds.left - bounds2.width + bounds.width

				if (left < width / 2) leftStyle.value = bounds.left

				if (props.forceTop || bottom > (height * 2) / 3) topStyle.value = bounds.top - bounds2.height
			}
		}, 50)
	}
}

const adaptAfterScroll = async (e: any) => {
	if (e.type === 'resize' || Math.abs(e.deltaY) > 2 || Math.abs(e.deltaX) > 2) {
		isOpen.value = false
	}
}

const addHandler = async () => {
	window.addEventListener('wheel', adaptAfterScroll)
	window.addEventListener('resize', adaptAfterScroll)
}
const removeHandler = async () => {
	window.removeEventListener('wheel', adaptAfterScroll)
	window.removeEventListener('resize', adaptAfterScroll)
}

const togglePopover = async (e: any) => {
	const ispopover = e.target.attributes.ispopover
	if (!ispopover) isOpen.value = !isOpen.value
}
const clickOutside = async (e: any) => {
	const ispopover = e.target.attributes.ispopover
	if (!ispopover) isOpen.value = false
}
</script>

<style scoped lang="sass">
$headerHeight: 0rem

.poComponent
	display: inline-block
	vertical-align: bottom
	position: relative
	.poComponent
		width: 100%

	.__popover
		z-index: 1
		margin: auto
		@apply border border-gray-50/5
		@apply shadow-lg
		@apply rounded-lg
		@apply bg-base-100

		&._left
			left: 0
		&._bottom
			bottom: 1.5rem
		&._right
			right: 0

		.__popover
			margin-right: 3rem
			margin-left: 3rem

	.fade-enter-active, .fade-leave-active
		transition: opacity 0.1s ease
		transition-delay: 60ms

	.fade-enter-from, .fade-leave-to
		transform: translateY(0rem)
		transition-delay: 0ms
		opacity: 0
</style>
