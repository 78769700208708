import { computed } from 'vue'
import type { Ref, ComputedRef } from 'vue'
import { defineStore } from 'pinia'
import type { StoreDefinition } from 'pinia'
import { useStorage } from '@vueuse/core'
import { now, MAX_DURATION_ON_CREATED_IN_SECONDS } from '@/libs/utils'
import http from '@/libs/http'

type AptCartItem = {
	price: number // in CENTS, inc.VAT
	currency: string
	trainerId: string
	locationId: string
	appointmentId: string
	initiatorId: string
	date: string // ='YYYY-MM-DD'
	time: string // ='hh:mm'
	created: number
	status: number
}

type Cart = AptCartItem[]

export const useCartStore: StoreDefinition = defineStore('cart', () => {
	const cart: Ref<Cart> = useStorage('cart', [])
	const currentCart: ComputedRef<Cart> = computed(() => {
		const nowTime = now()
		console.log(nowTime - (cart?.value[0]?.created || 0), MAX_DURATION_ON_CREATED_IN_SECONDS)
		return cart.value.filter((el) => nowTime - el.created < MAX_DURATION_ON_CREATED_IN_SECONDS)
	})
	const cartAptIdList: ComputedRef<Cart> = computed(() => cart.value.map((el: any) => el.appointmentId))

	const count = computed(() => currentCart.value.length)

	const sum: ComputedRef<number> = computed(() =>
		currentCart.value.reduce((partialSum, el) => partialSum + el.price, 0),
	)

	const vatPercent: ComputedRef<number> = computed(() => 20)
	const vat: ComputedRef<number> = computed(() => sum.value * (1 - 1 / (1 + vatPercent.value / 100)))

	// const vat: Ref<number> = computed(() => sum.value * (vatPercent.value / 100))

	const addItem = (data: AptCartItem) => {
		cart.value.push(data)
		// cart.value[0] = data
	}

	const removeItem = async (id: any, reservationKey: string) => {
		// try {
		// let deleteData: any = {}
		if (reservationKey)
			// deleteData =
			await http.delete(`/appointments/deletewithreservation/${id}/${reservationKey}`)
		// else deleteData =
		await http.deleteAuth(`/appointments/delete/${id}`)
		// } catch (error: any) {}
		// console.log('removeItem', deleteData.data);
		// if (deleteData.data) {
		removeItemFromCart(id)
		// }
	}

	const removeItemFromCart = (id: any) => {
		for (const idx in cart.value) {
			if (cart.value[idx].appointmentId == id) {
				cart.value.splice(+idx, 1)
				break
			}
		}
	}

	const clear = () => {
		cart.value = []
	}

	const mergeReservedCartToUser = async () => {
		try {
			const cart = currentCart.value.filter((el: any) => el.reservationKey && !el.initiatorId)
			if (cart.length) await http.postAuth(`/appointments/mergeresevations`, cart)
		} catch (error: any) {
			console.log(error.message)
		}
		return
	}

	return {
		cart,
		count,
		currentCart,
		cartAptIdList,
		addItem,
		removeItem,
		clear,
		vatPercent,
		vat,
		sum,
		mergeReservedCartToUser,
	}
})
