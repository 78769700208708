const moduleRoute = {
	path: '/cart',
	name: 'CartModule',
	component: () => import('../CartModule.vue'),
	meta: {
		isParent: true,
		navGroup: 'calendar',
		icon: 'ptp_calendar',
		allowedRoles: 'onlyTrainers',
	},
	children: [
		{
			path: '',
			name: 'CartView',
			component: () => import('../views/CartView.vue'),
			meta: {
				layout: 'AppLayoutDefault',
				allowedRoles: 'allUser',
			},
		},
	],
}

export default moduleRoute
