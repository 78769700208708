import { createI18n, useI18n } from 'vue-i18n'
import globalTranslations from '@/i18n/globalTranslations'

export default {
	install(app: any) {
		const allModules = import.meta.globEager('../modules/**/index.ts')
		const translations = globalTranslations
		Object.entries(allModules).forEach(([_path, _modul]: [string, any]) => {
			if (_modul.i18n) {
				translations.en = { ...translations.en, ..._modul.i18n.en }
				translations.de = { ...translations.de, ..._modul.i18n.de }
			}
		})

		const i18n = createI18n({
			legacy: false, // you must set `false`, to use Composition API
			messages: globalTranslations,
			locale: localStorage.locale || navigator.language.substring(0, 2) || 'en',
			fallbackLocale: 'en', // set fallback locale
		})

		app.use(i18n)
	},
}
