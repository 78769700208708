<script setup lang="ts">
// import { ref, shallowRef, toRef, watch, computed } from 'vue'
import { computed, toRef } from 'vue'
import { useField } from 'vee-validate'
// import { watchDebounced } from '@vueuse/core'
const emit = defineEmits(['update:modelValue'])

const props = defineProps({
	value: { type: Boolean, default: false },
	name: { type: String, required: true },
	label: { type: String, required: true },
	successMessage: { type: String, default: '' },
	modelValue: { type: Boolean, default: false },
	disabled: { type: Boolean, default: false },
})

const name = toRef(props, 'name')
const { errorMessage, meta } = useField(name, undefined, {
	initialValue: props.value,
})

const modelValue = computed({
	get() {
		return props.modelValue
	},
	set(nv: boolean) {
		emit('update:modelValue', nv)
	},
})
</script>

<template>
	<div class="ptp-input max-w-xl" :class="{ 'has-error': !!errorMessage, 'has-success': meta.valid }">
		<div class="form-controls">
			<label class="label justify-start gap-4 ptp-forcheckbox cursor-pointer bg-base-200/50 rounded-lg p-2.5">
				<input class="checkbox checkbox-primary" type="checkbox" v-model="modelValue" />
				<span class="">{{ label }}</span>
			</label>
		</div>
		<span class="help-message" v-if="errorMessage || meta.valid">
			<span v-if="errorMessage || meta.valid">
				{{ errorMessage || successMessage }}
			</span>
		</span>
	</div>
</template>

<style scoped lang="scss"></style>
