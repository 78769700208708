import { ref, watch, computed, type Ref, initCustomFormatter } from 'vue'
import { defineStore } from 'pinia'
import type { StoreDefinition } from 'pinia'
import type { TrainerData } from '@/core/types/types'
import ts from '@/libs/typesense'
import http from '@/libs/http'
import {
	doc,
	getFirestore,
	type DocumentData,
	getDoc,
	getDocs,
	query,
	collection,
	where,
	limit,
} from 'firebase/firestore'
import { keyBy, sortBy } from '@/libs/utils'
import { timeClass } from '@/libs/timeClass'

const hydrateState = (data: any) => {
	data.forEach((el: any) => {
		el._status = timeClass(el.dateTs || 0)
		return el
	})
	return data
}

export const useTTrainerStore: StoreDefinition = defineStore('ttrainer', () => {
	const db = getFirestore()
	const currentTrainer: Ref<TrainerData | null> = ref(null)
	const currentTrainerAppointments: Ref<any | null> = ref(null) // todo:types
	const openSlots = ref({})

	// const getTrainerById = async (_trainerIdent = ''): Promise<TrainerData | null> => {
	const getTrainerById = async (_trainerIdent = ''): Promise<void | null> => {
		try {
			if (!_trainerIdent) return null
			let result: TrainerData | null = null
			//!1
			// const tDoc = useFirestore(doc(db, `trainers`, `${_trainerIdent}`) || {})
			//!2 by Id
			const docRef = await doc(db, `trainers`, `${_trainerIdent}`)
			const docSnap: DocumentData = await getDoc(docRef)
			result = docSnap.data() || null
			if (!result) {
				//!3 by username fallback
				const q: any = query(collection(db, 'trainers'), where('userName', '==', _trainerIdent), limit(1))
				const querySnapshot = await getDocs(q)
				querySnapshot.forEach((doc: DocumentData) => (result = { id: doc.id, ...doc.data() }))
			}

			// 	//!res:
			currentTrainer.value = result
			// 	trainerData.value = result
			// 	// fetchTrainers([_trainerIdent])
			// 	//!4: ts
			// 	// try {
			// 	//   // const data = await ts.getDoc('trainers', _trainerId)
			// 	//   const data = await ts.docs('trainers', {
			// 	//     q: _trainerIdent,
			// 	//     query_by: 'userName',
			// 	//   })
			// 	//   trainerData.value = data[0]
			// 	// } catch (error: any) {
			// 	//   trainerData.value = {}
			// 	// }
			// 	// return result
			// 	return result
			return
		} catch (error) {
			console.error(error)
			return
		}
	}

	const getTrainersAppointments = async (_trainerId: any) => {
		try {
			const data = await ts.docs('appointments', {
				q: `${_trainerId}`,
				per_page: 64,
				query_by: 'trainerId',
				// sort_by: 'date:asc',
			})
			const dateHydrated: any = hydrateState(data)
			currentTrainerAppointments.value = keyBy(sortBy(dateHydrated, 'dateTs'), 'id')

			return
		} catch (error: any) {
			console.log(error.message)
			return
		}
	}

	const getOpenSlots = async (_trainerId: any) => {
		try {
			if (!_trainerId) return {}
			const res = await http.get(`appointments/openslots/${_trainerId}`)
			openSlots.value = res.data.data
			return
		} catch (error: any) {
			console.log(error.message)
			return {}
		}
	}

	return {
		currentTrainer,
		openSlots,
		getTrainerById,
		getTrainersAppointments,
		getOpenSlots,
	}
})
