import type { UserId } from '@/core/types/types'
import { GeoPoint } from 'geo-point'

const MAX_DURATION_ON_CREATED_IN_SECONDS = 15 * 60 // 5min
const MAX_DURATION_ON_PENDING_IN_SECONDS = 60 * 60 // 1hour

const stringToColor = (str: string) => {
	let hash = 0
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash)
	}
	let color = ''
	for (let i = 0; i < 3; i++) {
		const value = (hash >> (i * 8)) & 0xff
		color += ('00' + value.toString(16)).substr(-2)
	}
	const str1: any = '0xffffff'
	const str2: any = `0x${color}`

	const complement: string = ('000000' + (str1 ^ str2).toString(16)).slice(-6)
	return [`${color}`, `${complement}`]
}

const waitFor = (ms: number) => new Promise((r) => setTimeout(r, ms))

const currency = new Intl.NumberFormat('de-DE', {
	style: 'currency',
	currency: 'EUR',
})

const randInt = (upTo = 100) => Math.floor(Math.random() * upTo)

const geoDistance = (lat1: number, lng1: number, lat2: number, lng2: number, unit = 'km') => {
	if (lat1 == 0 || lng1 == 0 || lat2 == 0 || lng2 == 0) return ''
	// console.log(lat1, lng1, lat2, lng2)
	const startPoint = new GeoPoint(lat1, lng1)
	const endPoint = new GeoPoint(lat2, lng2)
	let distance = startPoint.calculateDistance(endPoint) / 1000

	let prefix = '~'
	if (distance < 0.2) {
		prefix = '<'
		distance = 0.2
	}
	// return `${prefix} ${Math.round(distance * 10) / 10} ${unit}`
	return Math.round(distance * 10) / 10
}

const sortByHelper = (key: string) => (a: any, b: any) => a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0
const sortBy = (arr: any[], key: string) => arr.concat().sort(sortByHelper(key))
const keyBy = (arr: any[], key: string) => (arr || []).reduce((r, x) => ({ ...r, [key ? x[key] : x]: x }), {})

const pad = (n: any, width = 3, z = '0') => {
	n = n + ''
	return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}
const now = () => Math.round(Date.now() / 1000)

const dayToInt = (date = '2022-01-01') => parseInt(date.replace('-', '').replace('-', ''))

const timeToInt = (date = '2022-01-01') => parseInt(date.replace(':', ''))

// const avatarImg = (id = '') => `https://robohash.org/${id}.png`
// const avatarImg = (id = '') => `https://robohash.org/${id}.png?set=set5`
const avatarImg = (id: UserId | null = null) =>
	!id ? '' : `https://robohash.org/set_set1/bgset_bg2/${id}.png?gravatar=yes`
// const avatarImg = (id = '') => (!id ? '' : `https://robohash.org/set_set5/${id||'nope'}.png?gravatar=yes`)

export {
	MAX_DURATION_ON_CREATED_IN_SECONDS,
	MAX_DURATION_ON_PENDING_IN_SECONDS,
	now,
	keyBy,
	sortBy,
	pad,
	stringToColor,
	waitFor,
	randInt,
	currency,
	geoDistance,
	dayToInt,
	timeToInt,
	avatarImg,
}
