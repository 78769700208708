<template lang="pug">
VueFinalModal(
	v-model="tempValue"
	:name="props.name"
	:esc-to-close="true",
	v-bind="$attrs"
	classes="modal-container",
	content-class="modal-content",
)
	.ptp-modal.flex.flex-col
		.ptp-modal-header.p-2.flex.items-center.justify-between(v-if="!$attrs.noControls")
			h4.h4.text-2xl.text-center.font-bold
				slot(name="title") 
			button.btn.btn-circle.btn-ghost.btn-sm(@click="close()")
				i.icon-ptp_cancel.text-xl.leading-3
		.divider.m-1

		.ptp-modal-content.overflow-auto
			slot

		.divider.m-1

		.ptp-modal-footer 
			slot(name="footer") 
		//- .ptp-modal-footer.pt-6.flex.items-center.justify-end.gap-3(v-if="!$attrs.noControls && !$attrs.noButtons")
			span(v-if="!$attrs.noCancelBtn")
				button.btn(@click="cancel()") cancel
			span(v-if="!$attrs.noOkBtn")
				button.btn(@click="confirm()") ok
		//- .ptp-modal-footer.pt-6(v-else)

</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { $vfm } from 'vue-final-modal'
const props = defineProps({
	// modelValue: { type: Boolean, default: false },
	name: { type: String, default: '' },
})
const emit = defineEmits(['update:modelValue', 'confirm', 'cancel'])

// const modelValue = computed({
// get() {
//	return props.modelValue
// },
// set() {
//	emit('update:modelValue', false)
// },
// })

const tempValue = ref(false)

const close = () => {
	$vfm.hide(props.name)
	// emit('update:modelValue', false)
}
const confirm = () => {
	console.log('confirm')
	emit('confirm', false)
	close()
}
const cancel = () => {
	emit('cancel', false)
	close()
}
</script>

<style scoped lang="scss">
::v-deep(.modal-container) {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ptp-modal {
	@apply p-3 m-1 shadow-lg;
	@apply bg-base-200;
	@apply border border-gray-500/40;

	max-height: calc(100vh - 2em);
	width: calc(100vw - 2em);
	max-width: 36rem;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

	--tw-bg-opacity: 1;
	--tw-scale-x: 0.9;
	--tw-scale-y: 0.9;

	// background-color: hsl(var(--b1) / var(--tw-bg-opacity));
	border-top-left-radius: var(--rounded-box, 1rem);
	border-top-right-radius: var(--rounded-box, 1rem);
	border-bottom-left-radius: var(--rounded-box, 1rem);
	border-bottom-right-radius: var(--rounded-box, 1rem);

	overflow-y: auto;
	overscroll-behavior: contain;
}

::v-deep(.vfm--overlay) {
	// background-color: rgba(120, 120, 120, 0.2);
	@apply bg-base-100/50 backdrop-blur-sm;
	// backdrop-filter: blur(3px);
}
</style>
