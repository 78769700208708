import axios from 'axios'

const instance: any = axios.create({
	baseURL: import.meta.env.VITE_TYPESENSE_BASE_URL,
	responseType: 'json',
	timeout: 20000,
})
// instance.defaults.headers.common['x-typesense-api-key'] = `Bearer ${localStorage.getItem('access_token')}`;
instance.defaults.headers.common['x-typesense-api-key'] = import.meta.env.VITE_TYPESENSE_API_SEARCH_KEY

const defaultParams = {
	q: '*',
	// 'x-typesense-api-key': import.meta.env.VITE_TYPESENSE_API_SEARCH_KEY,
	per_page: 16,
	page: 1,
	// query_by: 'city',
	// filter_by: `coordinates:(${lat},${lng}, 80 km)`,
	// sort_by: `coordinates(${lat},${lng}):asc`,
	// filter_by: 'coordinates:(48.182,16.376, 9999.9 km)',
	// sort_by: 'coordinates(48.182,16.376):asc',
}

const collectionParams = {
	locations: { query_by: 'city' },
	trainers: { query_by: 'lastName' },
	appointments: { query_by: 'date' },
}

const search = async (collectionId: string, params: any) => {
	try {
		const res = await instance.get(`/collections/${collectionId}/documents/search`, {
			params: { ...defaultParams, ...collectionParams, ...params },
		})
		// return (res.data?.hits || []).map((el: any) => el.document)
		return res.data.hits
	} catch (error: any) {
		console.error('ts Error:', error.message)
		return {}
		// } finally {
	}
}

const docs = async (collectionId: string, params: any) => {
	try {
		const res = await instance.get(`/collections/${collectionId}/documents/search`, {
			params: { ...defaultParams, ...collectionParams, ...params },
		})
		// return res.data.hits
		return (res.data?.hits || []).map((el: any) => el.document)
	} catch (error: any) {
		console.error('ts Error:', error.message)
		console.error('ts Error:', error.response.data.message)
		return []
		// } finally {
	}
}

const getDoc = async (collectionId: string, docId: any) => {
	try {
		const res = await instance.get(`/collections/${collectionId}/documents/${docId}`)
		return res.data
	} catch (error: any) {
		console.error('ts Error:', error.message)
		return {}
	}
}

const ts = { search, getDoc, docs }
export default ts
