import { ref, watch, computed, type Ref, initCustomFormatter } from 'vue'
import { defineStore } from 'pinia'
import type { StoreDefinition } from 'pinia'
import type { StudioId, Studio, TrainerData } from '@/core/types/types'
import ts from '@/libs/typesense'

export const useHomeStore: StoreDefinition = defineStore('home', () => {
	const studioId: Ref<StudioId | string> = ref('jrYBDzHJzbURpONHBr5NN3eY3fsS')
	const currentStudio: Ref<Studio | null> = ref(null)
	const currentTrainers: Ref<TrainerData[]> = ref([])

	const getStudio = async (_studioId: StudioId = studioId.value): Promise<void> => {
		const data = await ts.getDoc('locations', _studioId)
		currentStudio.value = data
		return
	}

	const getTrainer = async (_studioId: StudioId = studioId.value): Promise<void> => {
		console.log('getTrainer')
		const data = await ts.docs('trainers', {
			query_by: 'firstName,lastName,trainerType,',
			filter_by: `locationIds:${_studioId}`,
			sort_by: 'rating:desc,ratingCount:desc',
			per_page: 64,
		})
		currentTrainers.value = data
		return
	}

	//! init:
	getStudio()
	getTrainer()

	return {
		studioId,
		currentStudio,
		currentTrainers,
		// getStudio,
		// getTrainer,
	}
})
