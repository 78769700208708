import { ref, watch, computed, type Ref, initCustomFormatter } from 'vue'
import { defineStore } from 'pinia'
import type { StoreDefinition } from 'pinia'
import { useGeolocation } from '@vueuse/core'
import { useStorage } from '@vueuse/core'
import http from '@/libs/http'
import { geoDistance } from '@/libs/utils'

//! USE ALWAYS [lng,lat] in mapbox

type Coords = [number, number] | null

type UserLocation = {
	coords: Coords
	showMarker: boolean
	isSet: boolean
	datafrom: string //=',geo/server'
}

export const useUserStore: StoreDefinition = defineStore('user', () => {
	// const lang = useStorage('locale', 'en')
	const locale = useStorage('locale', 'en')

	const fetchedLocation = useStorage('userLocation', {
		isSet: false,
		fetchedAt: 0,
		coords: [null, null],
	})
	const userCoords: Ref<UserLocation> = ref({
		isSet: false,
		coords: null,
		showMarker: false,
		datafrom: '',
	})
	const geoLocation = useGeolocation()

	watch(geoLocation?.coords, async (nv: any) => {
		await setLocFromGeo()
	})

	watch(
		geoLocation?.error,
		async (nv: any) => {
			if (nv) await setLocFromIp()
		},
		{ immediate: true },
	)

	//!methods
	const setLocFromIp = async () => {
		console.log('userCoords', userCoords.value)
		if (!userCoords.value.isSet) {
			await getLocationFromIp()
			userCoords.value.coords = fetchedLocation.value.coords as unknown as Coords
			userCoords.value.showMarker = false
			userCoords.value.isSet = true
			userCoords.value.datafrom = 'server'
		}
	}
	const setLocFromGeo = async () => {
		const lat: number | null = geoLocation.coords?.value?.latitude
		const lng: number | null = geoLocation.coords?.value?.longitude
		// console.log('geoLocation', lng, lat);
		if (lat && lng && lat < 90 && lat > -90 && lng < 180 && lng > -180) {
			if (!userCoords.value.isSet || userCoords.value.datafrom === 'server') {
				userCoords.value.coords = [lng, lat]
				userCoords.value.showMarker = true
				userCoords.value.isSet = true
				userCoords.value.datafrom = 'geo'
			}
		}
	}

	// let isSetFirstTime = false
	const getLocationFromIp = async (): Promise<void> => {
		console.log('geoLocation getLocationFromIp')
		try {
			const diffTime = (Date.now() - fetchedLocation.value?.fetchedAt) / 1000
			// if older than 30 minutes
			if (diffTime > 60 * 30) {
				const res = await http.get(`/location/usercoordinates`)
				console.log(res.data.data)
				fetchedLocation.value = res.data.data
			}
		} catch (error) {
			console.error(error)
			// } finally {
			// eslint-disable-next-line no-unsafe-finally
			// return fetchedLocation.value?.coords as unknown as Coords
		}
		return
	}

	const getDistance = (coordinates: [number, number]) => {
		/* use [lat,lng]*/
		if (!coordinates) return 0
		const coords = userCoords.value.coords || [16.376, 48.182]
		if (!coords || !location) return ''
		const [lng1, lat1] = coords
		const [lat2, lng2] = coordinates //! attention: different lat/lng
		// console.log(geoDistance(lat1, lng1, lat2, lng2))
		return geoDistance(lat1, lng1, lat2, lng2)
	}

	const init = async () => {
		console.log('geoLocation init')
		// if (fetchedLocation.value.isSet)
		await setLocFromIp()
	}

	return {
		locale,
		fetchedLocation,
		userCoords,
		geoLocation,
		getDistance,
		init,
	}
})
