const moduleRoute = {
	path: '/calendar',
	name: 'CalendarModule',
	component: () => import('../CalendarModule.vue'),
	meta: {
		isParent: true,
		navGroup: 'calendar',
		icon: 'ptp_calendar',
		allowedRoles: 'onlyTrainers',
	},

	children: [
		{
			// path: '/dashboard/calendar',
			path: '/dashboard-trainer/calendar',
			name: 'CalendarDashboardView',
			// component: () => CalendarDashboardView,
			component: () => import('../views/CalendarDashboardView.vue'),
			meta: {
				layout: 'AppLayoutUser',
				navGroup: 'calendar',
				icon: 'ptp_calendar',
				title: 'Calendar',
				allowedRoles: 'onlyTrainers',
			},
		},
	],
}

export default moduleRoute
