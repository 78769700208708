<template lang="pug">

.dropdown.dropdown-end(v-if="authStore.currentProfile?.id")
  //- pre {{ authStore.currentProfile }}
  //- pre routes {{ routes }}
  label.btn.btn-ghost.rounded-full.px-2.flex.gap-2(tabindex="0")
    VUserImage.w-9.h-9

    span(class="hidden sm:block") {{authStore.currentUserData.firstName || authStore.currentProfile?.email.substring(0,16)+'...' }}
    span(class="hidden sm:block")
      svg.fill-current(xmlns="http://www.w3.org/2000/svg", width="20", height="20", viewBox="0 0 24 24")
        path(d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z")

  ul.mt-3.p-2.shadow.menu.gap-1.menu-compact.dropdown-content.bg-base-200.rounded-box.w-52(tabindex="0")
    template(v-for="(mainRoutes,navGroup) in routes")
      li(v-for="route in mainRoutes")
        RouterLink(:to="route.path") {{$t(`menu.${route.meta.title|| route.name}`)}}
    li
      a.link.link-hover.text-red-400(@click="authStore.logOut")
        //i.icon-ptp_logout
        span {{$t('logout')}}

</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import type { Ref } from 'vue'
import * as stores from '@/stores'
import { makeDashboardRoutes } from '@/router/navigationRoutes'

const authStore = stores.useAuthStore()
const dashboardRoutes: Ref<any> = ref(makeDashboardRoutes())
const routes = computed(() => dashboardRoutes.value?.[authStore.currentProfile?.role || ''] || {})

onMounted(() => {
	// console.log(makeDashboardRoutes());
	// console.log(makeDashboardRoutes());
	// console.log(makeDashboardRoutes());
	// console.log(makeDashboardRoutes());
})
</script>
