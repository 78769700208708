const moduleRoute = {
	path: '/payment',
	name: 'PaymentModul',
	component: () => import('../PaymentModul.vue'),
	meta: {
		layout: 'AppLayoutUser',
		navGroup: 'appointment',
		icon: 'ptp_payment',
		isParent: true,
		allowedRoles: 'allUser',
	},
	children: [
		{
			path: '/checkout',
			name: 'Checkout',

			component: () => import('../views/PaymentCheckout.vue'),
			meta: {
				redirect: '/cart',
				layout: 'AppLayoutDefault',
				allowedRoles: 'onlySignedIn',
			},
		},
		{
			path: '/checkout/payment',
			name: 'CheckoutPay',

			component: () => import('../views/PaymentCheckoutPay.vue'),
			meta: {
				layout: 'AppLayoutDefault',
				allowedRoles: 'onlySignedIn',
			},
		},
		{
			path: '/thankyou',
			name: 'ThankYou',

			component: () => import('../views/PaymentThankyou.vue'),
			meta: {
				layout: 'AppLayoutDefault',
				allowedRoles: 'onlySignedIn',
			},
		},
	],
}

export default moduleRoute
