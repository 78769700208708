import { ref, watch, computed, type Ref, initCustomFormatter } from 'vue'
import { defineStore } from 'pinia'
import type { StoreDefinition } from 'pinia'

export const useDashboardStore: StoreDefinition = defineStore('dashboard', () => {
	const count = ref(0)
	return {
		count,
	}
})
