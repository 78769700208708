export default {
	install(app: any) {
		const componentsGeneral = import.meta.globEager('../components/general/*.vue')
		const componentsForm = import.meta.globEager('../components/form/*.vue')
		const componentsAll = { ...componentsGeneral, ...componentsForm }
		Object.entries(componentsAll).forEach(([_path, _modul]: [string, any]) => {
			const path: any = _path + ''
			const componentName = path
				.split('/')
				.pop()
				.replace(/\.\w+$/, '')
			if (componentName.startsWith('V')) {
				app.component(componentName, _modul.default)
			}
		})
	},
}
