import { ref, computed, watch, type ComputedRef } from 'vue'
import type { Ref } from 'vue'
import { defineStore } from 'pinia'
import type { StoreDefinition } from 'pinia'
import { useFirestore } from '@vueuse/firebase'
import {
	doc,
	getFirestore,
	type DocumentData,
	getDoc,
	getDocs,
	query,
	collection,
	where,
	limit,
} from 'firebase/firestore'
import http from '@/libs/http'
import ts from '@/libs/typesense'
// import { useStorage } from '@vueuse/core'
import { useAuthStore } from '@/stores'
import { keyBy } from '@/libs/utils'

export const useMyTrainerStore: StoreDefinition = defineStore('myTrainer', () => {
	const authStore = useAuthStore()
	const db = getFirestore()

	const isTrainer = computed(() => authStore.currentProfile.role === 'trainer')
	const trainerId = computed(() => (isTrainer.value ? authStore.currentProfile.id : ''))
	const myTrainerData =
		isTrainer.value && trainerId.value ? useFirestore(doc(db, `trainers`, `${trainerId.value}`) || {}) : ref({})
	const aptCounts = ref({ appointments_created: 0 })

	watch(myTrainerData, async (nv: any, ov: any) => {
		const n = nv?.appointments_created || 0
		const o = ov?.appointments_created || 0
		if (n - o === 1) aptCounts.value.appointments_created = n
	})

	const saveTheme = async (weekTheme: any, dayOverwrites: any) => {
		const res = await http.postAuth(`trainer/weektheme/update`, {
			weekTheme,
			dayOverwrites,
		})
		return
	}

	return {
		myTrainerData,
		trainerId,
		aptCounts,
		saveTheme,
	}
})
