<template lang="pug">
AppLayout
	//- RouterView
	RouterView(v-slot='{ Component }')
		KeepAlive
			Component(:is='Component' :key='routeName')
</template>

<script setup lang="ts">
import AppLayout from '@/layouts/AppLayout.vue'
// import VSideNavBottom from '@/components/layout/VSideNavBottom.vue'
import { RouterLink, RouterView, useRouter } from 'vue-router'
import { computed, onMounted, watch } from 'vue'
import { useDark, useToggle } from '@vueuse/core'
import { useToast } from 'vue-toastification'
import * as stores from '@/stores'
// import { AuthStore } from './stores/authState'

// onMounted(() => {
// 	// const store = new AuthStore();
// 	// // console.log('store.userId$', store.userId$.value);
// 	// // setTimeout(() => {
// 	// // 	store.userId$.next(authStore.currentProfile.id)
// 	// // }, 4000)
// 	// store.payments$.subscribe((result: any) => { console.log('subs', result); })
// 	// store.userId$.next(authStore.currentProfile.id)
// })

const authStore = stores.useAuthStore()
const choreStore = stores.useChoreStore()
const myTrainerStore = stores.useMyTrainerStore()

const router = useRouter()
const toast = useToast()
const routeName = computed(() => router.currentRoute?.value?.name)

const aptCounts = computed(() => myTrainerStore.aptCounts)

watch(
	aptCounts,
	async (nv: any, ov: any) => {
		console.log('aptCounts', nv, ov)
		await choreStore.initForRole()
		toast.success('new Appointment created')
	},
	{ deep: true },
)
</script>

<style scoped></style>
