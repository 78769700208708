import { ref, computed, watch } from 'vue'
import { defineStore } from 'pinia'
import type { StoreDefinition } from 'pinia'
import * as stores from '@/stores'
import type { UserId, UserRole } from '@/core/types/types'
import { BehaviorSubject, combineLatest, filter, from, map, pipe, switchMap, tap } from 'rxjs'
import type { UserProfile } from 'firebase/auth'

export const useChoreStore: StoreDefinition = defineStore('chore', () => {
	const authStore = stores.useAuthStore()
	const paymentStore = stores.usePaymentStore()
	const locationStore = stores.useLocationStore()
	const globalTrainerStore = stores.useGlobalTrainerStore()
	const aptStore = stores.useAppointmentsStore()
	const cartStore = stores.useCartStore()
	const adminStore = stores.useAdminStore()

	// const userStore = useUserStore()

	let isInited = false
	const userRole = computed(() => authStore.currentProfile.role)

	const initForAll = async (): Promise<void> => {
		// await userStore.init()
		console.log('initForAll')
		await locationStore.fetchNearStudioList()
		await globalTrainerStore.fetchTranierForStudioIds(locationStore.studioIdsList) // todo
		// if(authStore.currentProfile.id) await cartStore.mergeReservedCartToUser()
	}

	const initForUnknownUser = async (): Promise<void> => {
		// await aptStore.fetchUsersAppointments(authStore.currentProfile.id)
		console.log('cartStore.cartAptIdList', cartStore.cartAptIdList)
		await aptStore.fetchOpenTrainerData(cartStore.cartAptIdList)
		await locationStore.fetchLocations(cartStore.cartAptIdList)
		await globalTrainerStore.fetchTrainers(aptStore.aptTrainerIds)
		console.log('cartStore.cartAptIdList', aptStore.aptTrainerIds)
	}

	const initTrainee = async (): Promise<void> => {
		await paymentStore.fetchPaymentList()
		await aptStore.fetchUsersAppointments(authStore.currentProfile.id)
		aptStore.fetchTrainerDataContacts(aptStore.appointmentIdList)
		locationStore.fetchLocations(aptStore.aptLocationIds)
		globalTrainerStore.fetchTrainers(aptStore.aptTrainerIds)
	}

	const initTrainer = async (): Promise<void> => {
		await paymentStore.fetchPaymentList()
		await aptStore.fetchTrainersAppointments(authStore.currentProfile.id)

		aptStore.fetchUserDataContacts(aptStore.appointmentIdList)
		locationStore.fetchLocations(aptStore.aptLocationIds)
		globalTrainerStore.fetchTrainers(aptStore.aptTrainerIds)
	}

	const initAdmin = async (): Promise<void> => {
		console.log('initAdmin')
		// await adminStore.fetchUsers()
		// await adminStore.fetchTrainers()
		await locationStore.fetchAllStores()
	}

	const initForRole = async (role: string = userRole.value): Promise<void> => {
		console.log('choreStore initForRole', role)
		if (role === null) initForUnknownUser()
		else if (role == 'trainee') initTrainee()
		else if (role == 'trainer') initTrainer()
		else if (role == 'admin') initAdmin()
	}
	initForAll()

	watch(
		userRole,
		async (nv: any, ov: any) => {
			if (nv !== null) cartStore.mergeReservedCartToUser()
			if (nv && nv != ov && !isInited) {
				initForRole(nv)
				isInited = true
			}
		},
		{ immediate: true },
	)

	// const userId$ = new BehaviorSubject<UserId | null>(authStore.currentProfile.id);
	// const userRole$ = new BehaviorSubject<UserRole | null>(authStore.currentProfile.role);
	// // const initUsers$ = combineLatest() from(userId$)
	// const initUsers$ = combineLatest([userId$, userRole$])
	// 	.pipe(

	// 		tap(([u, r]) => { console.log('u,r initUsers$', u, r); }),
	// 		tap(initForAll),
	// 		filter(([u, r]) => !!u),
	// 		map(([u, r]: any) => {
	// 			console.log('u,r', { u, r });
	// 			if (r === null) initForUnknownUser()
	// 			else if (r == 'trainee') initTrainee()
	// 			else if (r == 'trainer') initTrainer()
	// 			else if (r == 'admin') initAdmin()
	// 			return [u, r]
	// 			// if (!userId) {
	// 			// 	console.log('no userId');
	// 			// 	return of({
	// 			// 		accounts: [],
	// 			// 		userAccesses: [],
	// 			// 		user: null,
	// 			// 	});
	// 			// } else return store.usePaymentStore().fetchPaymentList()
	// 		}))
	// 	.subscribe((res) => {
	// 		console.log('u,r subs', res)
	// 	})

	return {
		initForRole,
	}
})
