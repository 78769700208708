<template lang="pug">

.relative
	div.rounded-full
		div(v-bind="getRootProps()")
			input(v-bind="getInputProps()")
			template(v-if="previewFile")
				VUserImage.w-32.h-32.m-auto(:previewFile="previewFile")
			template(v-else)
				slot
			template(v-if="!previewFile")
				.flex.justify-center.items-center.gap-2.m-3
					button.btn.btn-ghost.btn-xs Change Image: just drag&drop it here
		.ptp-dropzone.dropzone.rounded-full.flex.justify-center.items-center(:class="{'isDragEnter':isDragEnter}")
			span {{t('DropHere')}}
	template(v-if="previewFile")
		.flex.justify-center.items-center.gap-2.m-3
			button.btn.btn-primary.btn-xs(@click="uploadFile()" :class="{'loading':isUploading}") upload
			button.btn.btn-error.btn-xs(@click="previewFile=''") cancel

</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useDropzone } from 'vue3-dropzone'
import type { FileUploadOptions } from 'vue3-dropzone'
import { useI18n } from 'vue-i18n'
import http from '@/libs/http'
import { useAuthStore } from '@/stores'
import { $vfm } from 'vue-final-modal'
import { EventBus, useToast } from 'vue-toastification'
import * as fbstore from 'firebase/storage'

const { t } = useI18n()

const uploadProgress = ref(0)
const isDragEnter = ref(false)
const isDragActive = ref(false)
const previewFile = ref('')
const actualFile = ref({})

onMounted(() => {
	// const dropzone: any = this.$refs.dropzone;
	window.addEventListener('dragenter', (e: any) => {
		isDragEnter.value = true
		e.dataTransfer.dropEffect = 'copy'
		e.dataTransfer.effectAllowed = 'copy'
	})

	window.addEventListener('dragleave', (e: any) => {
		isDragEnter.value = false
		e.dataTransfer.dropEffect = 'copy'
		e.dataTransfer.effectAllowed = 'copy'
		e.preventDefault()
	})

	window.addEventListener('dragover', (e: any) => {
		isDragEnter.value = true
		e.dataTransfer.dropEffect = 'copy'
		e.dataTransfer.effectAllowed = 'copy'
		e.preventDefault()
	})

	window.addEventListener('drop', (e: any) => {
		uploadProgress.value = 0
		isDragEnter.value = false
		e.dataTransfer.dropEffect = 'copy'
		e.dataTransfer.effectAllowed = 'copy'
		// e.preventDefault()
		// this.addFiles(e)
	})
})
const isUploading = ref(false)
const uploadFile = async () => {
	console.log('uploadFile')
	isUploading.value = true
	if (!actualFile.value) return
	const formData = new FormData()
	formData.append('file', actualFile.value as any)
	try {
		const storage = fbstore.getStorage()
		const fullPath = `userImages/${useAuthStore().currentProfile.id}/${(actualFile.value as any).name}`
		const storageRef = fbstore.ref(storage, fullPath)
		const sp = await fbstore.uploadBytes(storageRef, actualFile.value as any)
		// const basePath = 'https://firebasestorage.googleapis.com/v0/b/hero-ptp-dev2.appspot.com/o/'
		const basePath = 'https://firebasestorage.googleapis.com/v0/b/hero-ptp-dev2.appspot.com/o/'
		const encodedUrl = basePath + encodeURIComponent(fullPath) + '?alt=media'
		console.log(encodedUrl)

		await useAuthStore().updateUserData({
			photoURL: encodedUrl,
		})

		// }).then((snapshot) => {
		// console.log(snapshot);
		// console.log('Uploaded a blob or file!');
		// });

		// await useAuthStore().updateImage()

		// await http.postAuth('user/image/upload', formData)
		await useAuthStore().updateImage()
		// $vfm.hide('')

		previewFile.value = ''
		useToast().success(t('account.imageUploaded'))
	} catch (error: any) {
		useToast().error(t(`errors.${error.message}`))
	}
	isUploading.value = false
	// async SendFilesNew(close: any = null) {
	// 		try {
	// 			this.uploadProgress = 0.1
	// 			const formData = new FormData()
	// 			// console.log(this.uploadFiles[ i ])
	// 			for (let i in this.uploadFiles) formData.append("doFiles", this.uploadFiles[i])
	// 			const config = await this._config()

	// 			config.onUploadProgress = (progressEvent: any) => {
	// 				this.uploadProgress =
	// 					(progressEvent.loaded / progressEvent.total) * 100
	// 			}

	// 			let url = env.apiUrl + `/api/v2/uploadfiles/${this.project}`
	// 			if (this.rootfolder) url = env.apiUrl + `/api/v1/file/upload/${btoa(this.rootfolder)}`
	// 			else if (this.locationId) url = env.apiUrl + `/api/v2/uploadfiles/${this.project}/${this.locationId}`
	// 			// console.log("url", url)
	// 			const resp = await axios.post(url, formData, config)
	// 			// console.log("done!")
	// 			await waitFor(2500)
	// 			this.$emit("refresh")
	// 			if(close) close()
	// 			this.uploadProgress = 0
	// 		} catch (error: any) {
	// 			console.error(error.message)
	// 			console.error(error)
	// 			this.uploadProgress = 0
	// 		} finally {
	// 		}
	// 	}
	return
}

// const onDrop = (acceptFiles: any, rejectReasons: any) => {
// // console.log(rejectReasons)
// if (acceptFiles[0]) {
// actualFile.value = acceptFiles[0]
// previewFile.value = URL.createObjectURL(acceptFiles[0])
// }
// return
// }

const onDropAccepted = (acceptFiles: any, e: Event) => {
	if (acceptFiles[0]) {
		actualFile.value = acceptFiles[0]
		previewFile.value = URL.createObjectURL(acceptFiles[0])
	}
	return
}
const onDropRejected = (rejectReasons: any, b: Event) => {
	const error = rejectReasons[0].errors[0]
	console.log(error.code)
	console.log(error.message)
	useToast().error(t(`errors.${error.code}`))
	// console.log(b);
	return
}

const { getRootProps, getInputProps, ...rest } = useDropzone({
	// accept: { 'image/*': [] },
	multiple: false,
	maxFiles: 1,
	maxSize: 1.2 * 4000 * 4000,
	// init: () => {
	// this.on("maxfilesexceeded", function (file) {
	// this.removeAllFiles();
	// this.addFile(file);
	// });
	// },
	onDropRejected,
	onDropAccepted,
	// onDrop
})
</script>

<style scoped lang="scss">
.dropzone {
	pointer-events: none;
}

.ptp-dropzone {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	z-index: 999;
	width: 100%;
	height: 100%;
	height: 0;
	background-color: rgba(0, 0, 0, 0.5);
	transition: all 0.025s;
	// display: table;
	// text-shadow: 1px 1px 2px #000;
	color: #fff;
	// background: rgba(0, 0, 0, 0.45);
	// font-weight: 700;
	visibility: hidden;
	opacity: 0;
	// font-size: 1px

	&.isDragEnter,
	&:hover {
		opacity: 1;
		height: 100%;
		width: 100%;
		// height: 120px;
		visibility: initial;
	}
}
</style>
