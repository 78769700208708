const meta = {
	layout: 'AppLayoutUser',
	navGroup: 'account',
	icon: 'ptp_appointment',
	allowedRoles: 'onlySignedIn',
}

const moduleRoute = {
	path: '/dashboard-trainer/',
	name: 'DashboardTrainerModule',
	// component: () => DashboardTrainerModule,
	component: () => import('../DashboardTrainerModule.vue'),

	meta: { ...meta, isParent: true },
	children: [
		{
			path: 'appointments',
			name: 'DashboardTrainerAppointments',
			// component: () => DashboardTrainerAppointments,
			component: () => import('../views/DashboardTrainerAppointments.vue'),

			meta,
		},
	],
}

export default moduleRoute
