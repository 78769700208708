<template lang="pug">
//- pre {{location}}
//- pre {{location.coords.value}}
#mainMap
</template>

<script setup lang="ts">
import {
	// onActivated,
	// onBeforeMount,
	// onBeforeUpdate,
	onMounted,
	ref,
	watch,
	computed,
} from 'vue'
import type { Ref } from 'vue'
import mapboxgl, { type LngLatLike } from 'mapbox-gl'
import { Map, Marker } from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { waitFor } from '@/libs/utils'
import { useUserStore } from '@/stores'

const userStore = useUserStore()

const mapBoxKey = import.meta.env.VITE_MAPBOX_KEY

let map: Map | null = null
// let allMarker: any[] = []

const props = defineProps({ coords: { type: Array, default: null } })

const gyms: string[] = ['mcfit', 'goldsgym', 'johnreed']
const isMapSet = ref(false)
const isMyMarkerSet = ref(false)

// onMounted(async () => {
const init = async () => {
	await waitFor(200)
	await makeMapBox()
	await makeMapMaker()
}

const makeMapBox = async () => {
	mapboxgl.accessToken = mapBoxKey
	const style = 'mapbox://styles/propellercat/ckr4kj4g90jl817nzqsf0fc67'
	// const style = 'mapbox://styles/mapbox/satellite-streets-v11'
	// const style = 'mapbox://styles/propellercat/cladmvszr000014o36odnforo'
	// const style = 'mapbox://styles/propellercat/cladmxn23000714plauxea73u'
	isMapSet.value = false
	if (!map) {
		const c: any = props.coords
		map = await new mapboxgl.Map({
			container: 'mainMap',
			style,
			center: [c[1], c[0]],
			zoom: 14,
			// projection: 'globe',
			projection: { name: 'globe' },
			// { name: "globe" | "albers" | "equalEarth" | "equirectangular" | "lambertConformalConic" | "mercator" | "naturalEarth" | "winkelTripel"; center?: [number, number] | undefined; parallels?: [...] | undefined; }
		})

		map.on('wheel', (event: any) => {
			if (event.originalEvent.ctrlKey) return
			if (event.originalEvent.metaKey) return
			if (event.originalEvent.altKey) return
			event.preventDefault()
		})

		await map.on('style.load', () => {
			if (map) map.setFog({})
		})
	}
	isMapSet.value = true
}

const makeMapMaker = async () => {
	await waitFor(200)
	if (map) {
		const c = props.coords
		const coords = [c[1], c[0]]
		const el = document.createElement('div')
		el.className = `marker green `
		const mmarker: Marker = new Marker(el)
		mmarker.setLngLat(coords as any).addTo(map)
	}
}

watch(
	() => computed(() => props.coords),
	(nv) => {
		if (nv.value) init()
	},
	{ immediate: true },
)
</script>

<style scoped lang="scss">
#mainMap {
	width: 100%;
	height: 16rem;

	// height: 100%
	// height: 800px;
	// padding-bottom: 100%;
	// // height: 500px;
	// min-height: 100px;
	// max-height: 240px;
	// min-width: 100px;
}
</style>
