import { ref, computed } from 'vue'
import type { Ref, ComputedRef } from 'vue'
import { defineStore } from 'pinia'
import type { StoreDefinition } from 'pinia'
import dayjs from 'dayjs'
import ts from '@/libs/typesense'

import {
	now,
	keyBy,
	MAX_DURATION_ON_CREATED_IN_SECONDS,
	MAX_DURATION_ON_PENDING_IN_SECONDS,
	sortBy,
} from '@/libs/utils'
import http from '@/libs/http'
import { timeClass } from '@/libs/timeClass'

// import cache from '@/libs/cache'
// import localforage from 'localforage'

// // const fg = await localforage.setItem('somekey', 'hi');
// // const value = await localforage.getItem('somekey');

// const a = await cache.set('k','d')
// const b = await cache.get('k')
// console.log(a, b);
// import { useAuthStore } from '@/stores'

// const isCr = el.status == 'created'
// const isCrOver =
//   nowTime - el.created > MAX_DURATION_ON_CREATED_IN_SECONDS
// if (isCr && isCrOver) return false

// const isPn = el.status == 'pending'
// const isPnOver =
//   nowTime - el.created > MAX_DURATION_ON_PENDING_IN_SECONDS
// if (isPn && isPnOver) return false

export const useAppointmentsStore: StoreDefinition = defineStore('appointments', () => {
	const appointments: Ref<any> = ref({})
	const appointmentIdList: any = computed(() => Object.keys(appointments.value))
	const appointmentList: ComputedRef<any> = computed(() => Object.values(appointments.value))

	const appointmentsPast: Ref<any> = computed(() => {
		const data = sortBy(appointmentList.value, 'dateTs')
		data.reverse()
		const inOneHour = dayjs().unix() + 60 * 60 * 1
		const filtered = data.filter((el: any) => {
			if (el.status == 'created') return false
			const isComing = el.dateTs > inOneHour
			if (isComing) return false
			return true
		})
		return keyBy(filtered, 'id')
	})

	// const appointmentsFuture: Ref<any> = computed(() => {
	//   const data = sortBy(appointmentList.value, 'dateTs')
	//   const filtered = data.filter((el: any) => {
	//     if (el.status == 'created') return false
	//     return true
	//   })
	//   return keyBy(filtered, 'id')
	// })

	const appointmentsFuture: Ref<any> = computed(() => {
		const data = sortBy(appointmentList.value, 'dateTs')
		const beforeOneHour = dayjs().unix() - 60 * 60 * 2
		const filtered = data.filter((el: any) => {
			if (el.status == 'created') return false
			const isOver = el.dateTs < beforeOneHour
			if (isOver) return false

			return true
		})
		return keyBy(filtered, 'id')
	})

	const aptLocationIds: ComputedRef = computed(() => [
		...new Set(appointmentList.value.map((el: any) => el.locationId)),
	])
	const aptTrainerIds: ComputedRef = computed(() => [...new Set(appointmentList.value.map((el: any) => el.trainerId))])

	// only for trainer to see who assigned:
	const aptUserContact: Ref<any> = ref({})
	const aptTrainerContact: Ref<any> = ref({})
	// const aptTrainerContact: any = computed({
	//   get() { return cache.get('aptTrainerContact') },
	//   set: async (nv: string) => {
	//     console.log('nv', nv);
	//     cache.set('aptTrainerContact', nv)
	//   },
	// })
	const aptInitiatorList: ComputedRef = computed(() => [
		...new Set(appointmentList.value.map((el: any) => el.initiatorId)),
	])

	const openSlots: Ref<any> = ref({})

	const appointmentsByDate = computed(() => {
		const result: any = {}
		for (const appt of appointmentList.value) {
			const date = appt.date || 'noDate'
			if (!result[date]) result[date] = []
			result[date].push(appt)
		}
		return result
	})

	//!methods

	const createAppointment = async (data: any) => {
		try {
			// console.log(data );
			const newApt = await http.post(`appointments/create`, data)
			// console.log(newApt.data);
			return newApt.data
		} catch (error: any) {
			console.error(error.message)
			const errorKey = error.response?.data?.message || error.message
			console.log(errorKey)
			throw new Error(errorKey)
		}
	}

	const hydrateState = (data: any) => {
		data.forEach((el: any) => {
			el._status = timeClass(el.dateTs || 0)
			return el
		})
		return data
	}

	const cancelAppointment = async (aptId: any) => {
		// console.log('cancelAppointment', aptId);
		try {
			const newApt = await http.deleteAuth(`appointments/delete/${aptId}`)
			// const newApt = await http.postAuth(`appointments/cancel`, { aptId })
			if (newApt.data === true) delete appointments.value[aptId]
			return newApt.data.data
		} catch (error: any) {
			console.error(error.message)
			const errorKey = error.response?.data?.message || error.message
			console.log(errorKey)
			throw new Error(errorKey)
		}
	}

	const fetchTrainersAppointments = async (trainerId: any) => {
		try {
			const data = await ts.docs('appointments', {
				q: `${trainerId}`,
				per_page: 64,
				query_by: 'trainerId',
				// sort_by: 'date:asc',
			})
			// appointments.value = keyBy(sortBy(data, 'dateTs'), 'id')
			const dateHydrated: any = hydrateState(data)
			appointments.value = keyBy(sortBy(dateHydrated, 'dateTs'), 'id')

			return
		} catch (error: any) {
			console.log(error.message)
			return
		}
	}

	const fetchUsersAppointments = async (userId: any) => {
		console.log('gg uu fetchUsersAppointments')
		try {
			const data = await ts.docs('appointments', {
				q: `${userId}`,
				per_page: 64,
				query_by: 'initiatorId',
			})
			// appointments.value = keyBy(sortBy(data, 'dateTs'), 'id')
			const dateHydrated: any = hydrateState(data)
			appointments.value = keyBy(sortBy(dateHydrated, 'dateTs'), 'id')
			return
		} catch (error: any) {
			console.log(error.message)
		}
	}

	const fetchAppointment = async (aptId: any) => {
		try {
			console.log('fetchAppointment')
			if (!aptId) return {}
			const appointmentData = JSON.parse(JSON.stringify(appointments.value[aptId]))
			const trainerData =
				appointmentData.trainerId || false ? await ts.getDoc('trainers', appointmentData.trainerId) : {}
			const locationData =
				appointmentData.locationId || false ? await ts.getDoc('locations', appointmentData.locationId) : {}
			return { appointmentData, trainerData, locationData }
		} catch (error: any) {
			console.log(error.message)
			return {}
		}
	}

	// only for trainers:
	const fetchUserDataContacts = async (idList: string[]): Promise<void> => {
		const filterIdList = idList.filter((id: string) => !aptUserContact.value[id])
		try {
			const data = await http.getAuth(`/appointments/userdata`, {
				params: { ids: filterIdList.join(',') },
			})
			aptUserContact.value = { ...aptUserContact.value, ...data.data }
			return
		} catch (error: any) {
			console.log(error.message)
			return
		}
	}

	// only for trainees/users:
	const fetchTrainerDataContacts = async (idList: string[]): Promise<void> => {
		const _aptTrainerContact = await aptTrainerContact.value
		const filterIdList = idList.filter((id: string) => !_aptTrainerContact[id])
		try {
			const data = await http.getAuth(`/appointments/trainerdata`, {
				params: { ids: filterIdList.join(',') },
			})
			aptTrainerContact.value = { ..._aptTrainerContact, ...data.data.data }
			return
		} catch (error: any) {
			console.log(error.message)
			return
		}
	}

	const fetchOpenTrainerData = async (
		// only for user that has no assigned appointment
		idList: string[],
	): Promise<void> => {
		console.log('fetchOpenTrainerData')
		const filterIdList = idList.filter((id: string) => !aptTrainerContact.value[id])
		console.log('filterIdList', filterIdList)
		try {
			const data = await ts.docs('trainers', {
				q: '*',
				per_page: 250,
				query_by: 'firstName,lastName',
				// filter_by: `id:[${filterIdList.join(',')}]`,
			})
			const rData = keyBy(
				data.map((el: any) => ({
					firstName: el.firstName,
					lastName: el.lastName,
					id: el.id,
				})),
				'id',
			)
			// rData
			aptTrainerContact.value = { ...aptTrainerContact.value, ...rData }
			// aptTrainerContact.value = data
			return
		} catch (error: any) {
			console.log(error.message)
			return
		}
	}

	const listOpenSlots = async (trainerId: any) => {
		try {
			if (!trainerId) return {}
			const res = await http.get(`appointments/openslots/${trainerId}`)
			console.log('res', res)
			openSlots.value = res.data.data
			return res.data.data
		} catch (error: any) {
			console.log(error.message)
			return {}
		}
	}

	return {
		// list,
		appointments,
		appointmentsPast,
		appointmentsFuture,

		appointmentList,

		aptTrainerIds,
		aptInitiatorList,
		aptLocationIds,
		appointmentIdList,
		appointmentsByDate,

		aptUserContact,
		aptTrainerContact,

		openSlots,

		createAppointment,
		cancelAppointment,
		fetchTrainersAppointments,
		fetchUsersAppointments,
		fetchAppointment,
		fetchUserDataContacts,
		fetchTrainerDataContacts,
		fetchOpenTrainerData,
		listOpenSlots,
	}
})
