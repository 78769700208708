<template lang="pug">
.default
	header.z-50
		VHeader
	main
		slot
	footer.z-50
		VFooter
</template>

<script setup lang="ts">
import VFooter from '@/components/layout/VFooter.vue'
import VHeader from '@/components/layout/VHeader.vue'
import { onMounted } from 'vue'

onMounted(() => {
	console.log('ww onmounted')
})
</script>

<style scoped lang="scss">
.default {
	height: 100vh;
	display: flex;
	flex-direction: column;
}

main {
	flex-grow: 1;
}
</style>
