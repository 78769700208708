const meta = {
	layout: 'AppLayoutUser',
	navGroup: 'admin',
	icon: 'ptp_payment',
	allowedRoles: 'onlyAdmins',
}

const moduleRoute = {
	path: '/admin',
	name: 'AdminModule',
	// component: () => AdminModule,
	component: () => import('../AdminModule.vue'),

	meta: { ...meta, isParent: true },
	children: [
		{
			path: 'locations',
			name: 'AdminStudioView',
			// component: () => AdminStudioView,
			component: () => import('../views/AdminStudioView.vue'),

			meta,
		},
		{
			path: 'users',
			name: 'AdminUsersView',
			// component: () => AdminUsersView,
			component: () => import('../views/AdminUsersView.vue'),

			meta,
		},
		{
			path: 'trainers',
			name: 'AdminTrainerView',
			// component: () => AdminTrainerView,
			component: () => import('../views/AdminTrainerView.vue'),

			meta,
		},
	],
}

export default moduleRoute
