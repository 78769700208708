const meta = {
	layout: 'AppLayoutDefault',
	allowedRoles: 'allUser',
}

const moduleRoute = {
	path: '/',
	name: 'HomeModule',
	// component: () => HomeModule,
	component: () => import('../HomeModule.vue'),
	meta: { ...meta, isParent: true },
	children: [
		{
			path: '/',
			name: 'HomeJohnReedView',
			// component: () => HomeJohnReedView,
			component: () => import('../views/HomeJohnReedView.vue'),
			meta: {
				layout: 'AppLayoutDefault',
				title: 'Home',
				allowedRoles: 'allUser',
			},
		},
		{
			path: '/home',
			name: 'HomeJohnReedView2',
			// component: () => HomeJohnReedView,
			component: () => import('../views/HomeJohnReedView.vue'),
			meta: {
				layout: 'AppLayoutDefault',
				title: 'Home',
				allowedRoles: 'allUser',
			},
		},
	],
}

export default moduleRoute
