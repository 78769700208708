<template lang="pug">
.block.flex.gap-1(v-if="!isNaN(props.rating) && !isNaN(props.ratingCount)")
	.rating.rating-half(:class="{'rating-md':props.size=='md','rating-sm':props.size=='sm'}")
		input.cursor-default.mask.mask-star-2.mask-half-1.bg-orange-400(type="radio", disabled, :name="`rating-2 trainer-${randomNumber}`", :checked="Math.round(props.rating*2)===1")
		input.cursor-default.mask.mask-star-2.mask-half-2.bg-orange-400(type="radio", disabled, :name="`rating-2 trainer-${randomNumber}`", :checked="Math.round(props.rating*2)===2")
		input.cursor-default.mask.mask-star-2.mask-half-1.bg-orange-400(type="radio", disabled, :name="`rating-2 trainer-${randomNumber}`", :checked="Math.round(props.rating*2)===3")
		input.cursor-default.mask.mask-star-2.mask-half-2.bg-orange-400(type="radio", disabled, :name="`rating-2 trainer-${randomNumber}`", :checked="Math.round(props.rating*2)===4")
		input.cursor-default.mask.mask-star-2.mask-half-1.bg-orange-400(type="radio", disabled, :name="`rating-2 trainer-${randomNumber}`", :checked="Math.round(props.rating*2)===5")
		input.cursor-default.mask.mask-star-2.mask-half-2.bg-orange-400(type="radio", disabled, :name="`rating-2 trainer-${randomNumber}`", :checked="Math.round(props.rating*2)===6")
		input.cursor-default.mask.mask-star-2.mask-half-1.bg-orange-400(type="radio", disabled, :name="`rating-2 trainer-${randomNumber}`", :checked="Math.round(props.rating*2)===7")
		input.cursor-default.mask.mask-star-2.mask-half-2.bg-orange-400(type="radio", disabled, :name="`rating-2 trainer-${randomNumber}`", :checked="Math.round(props.rating*2)===8")
		input.cursor-default.mask.mask-star-2.mask-half-1.bg-orange-400(type="radio", disabled, :name="`rating-2 trainer-${randomNumber}`", :checked="Math.round(props.rating*2)===9")
		input.cursor-default.mask.mask-star-2.mask-half-2.bg-orange-400(type="radio", disabled, :name="`rating-2 trainer-${randomNumber}`", :checked="Math.round(props.rating*2)===10")

	//- small.text-xs.text-gray-400 {{props.rating}} ({{props.ratingCount}})
	//- small.text-xs.text-gray-400 ({{props.ratingCount}})
	//- small /{{props.ratingCount}}
	small.text-gray-500(v-if="props.ratingCount") ({{props.ratingCount}})
</template>

<script setup lang="ts">
const props = defineProps({
	rating: { type: Number, default: 0 },
	ratingCount: { type: Number, default: 0 },
	size: { type: String, default: 'sm' },
})
const randomNumber = Math.floor(Math.random() * 9999)
</script>

<style lang="scss"></style>
