<template lang="pug">
component(:is="layout")
	//- .mt-12 // if header is fixed
	slot(v-if="isLoaded")
</template>

<script setup lang="ts">
import { ref, shallowRef, watch } from 'vue'
import { useRoute } from 'vue-router'

import AppLayoutDefault from '@/layouts/AppLayoutDefault.vue'
const layout = shallowRef(AppLayoutDefault)
const route = useRoute()
const isLoaded = ref(false)
watch(
	() => route.meta,
	async (meta: any) => {
		try {
			// if (!meta.layout) return
			// const layout = meta?.layout
			// @ts-ignore
			// const component = await import(`../layouts/${layout || '-'}.vue`)
			const component = await import(`../layouts/${meta?.layout || '-'}.vue`)
			layout.value = component?.default || AppLayoutDefault
		} catch (e: Error | any) {
			layout.value = AppLayoutDefault
		} finally {
			isLoaded.value = true
		}
	},
	// { immediate: true }
)
</script>
