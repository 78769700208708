<script setup lang="ts">
// import { ref, shallowRef, toRef, watch, computed } from 'vue'
import { computed, toRef } from 'vue'
import { useField } from 'vee-validate'
// import { watchDebounced } from '@vueuse/core'
const emit = defineEmits(['update:modelValue'])

const props = defineProps({
	options: { type: Array, default: () => [] },
	type: { type: String, default: 'text' },
	value: { type: Array, default: () => [] },
	name: { type: String, required: true },
	label: { type: String, required: true },
	successMessage: { type: String, default: '' },
	modelValue: { type: Array, default: () => [] },
	disabled: { type: Boolean, default: false },
})

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name')
const {
	value: inputValue,
	errorMessage,
	handleBlur,
	handleChange,
	meta,
} = useField(name, undefined, {
	initialValue: props.value,
})

const checkHander = async (opt: string) => {
	let arr = inputValue.value || []
	if (arr.includes(opt)) arr = arr.filter((e) => e !== opt)
	else arr.push(opt)
	handleChange(arr)
}
</script>

<template>
	<div class="ptp-input" :class="{ 'has-error': !!errorMessage, 'has-success': meta.valid }">
		<label v-if="label" :for="name" class="label pb-0 uppercase">
			<span class="label-text">{{ label }}</span>
		</label>
		<div class="divider"></div>

		<label class="cursor-pointer label max-w-xl" v-for="(opt, idx) in props.options" :key="idx">
			<span class="label-text">{{ opt }}</span>

			<input
				class="checkbox"
				:name="opt + ''"
				:id="opt + ''"
				type="checkbox"
				:value="inputValue"
				:disabled="disabled"
				@input="checkHander(opt + '')"
				@blur="handleBlur"
				:checked="(inputValue || []).includes(opt)"
			/>
			<!-- :no-true-value="[]"
				:no-false-value="[]" -->
		</label>

		<span class="help-message" v-if="errorMessage || meta.valid">
			<span v-if="errorMessage || meta.valid">
				{{ errorMessage || successMessage }}
			</span>
		</span>
	</div>
</template>

<style scoped lang="scss"></style>
