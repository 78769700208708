<template lang="pug">
figure.w-full.h-full
	template(v-if="props.previewFile")
		.ptp-userimage(:style="`background-image:url(${props.previewFile})`")
	template(v-else-if="props.userId")
		template(v-if="userData.photoURL")
			.ptp-userimage(:style="`background-image:url(${userData.photoURL})`")
		template(v-else)
			.ptp-userimage(:style="`background-image:url(${avatarImg(userId)})`")
	template(v-else)
		.ptp-userimage(:style="`background-image:url(${authStore.photoURL})`")

</template>

<script setup lang="ts">
import { avatarImg } from '@/libs/utils'
import { useAppointmentsStore, useAuthStore, useAdminStore } from '@/stores'
import { computed } from 'vue'
const authStore = useAuthStore()
const aptStore = useAppointmentsStore()
const adminStore = useAdminStore()
const props = defineProps({
	userId: { type: String, default: '' },
	previewFile: { type: String, default: '' },
})
const userData = computed(
	() => aptStore?.aptUserContact?.[props.userId] || adminStore?.adminData.users?.[props.userId] || {},
)
</script>

<style lang="scss">
.ptp-userimage {
	@apply bg-cover w-full h-full rounded-full border bg-center;
}
</style>
