import router from './router'

export default {
	install(app: any) {
		const allModules = import.meta.globEager('../modules/**/index.ts')
		Object.entries(allModules).forEach(([_path, _modul]: [string, any]) => {
			if (_modul.router) {
				router.addRoute(_modul.router)
			}
		})
		console.log(router.getRoutes())
		app.use(router)
	},
}
