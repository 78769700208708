<template lang="pug">
figure.VTrainerImage.w-full.h-full
	template(v-if="props.trainerId")
		//- pre {{props.trainerId}}
		template(v-if="trainerData.photoURL")
			.ptp-trainerimage(:style="`background-image:url(${trainerData.photoURL})`")
		template(v-else)
			.ptp-trainerimage(:style="`background-image:url(${avatarImg(props.trainerId)})`")
	template(v-else)
		.ptp-trainerimage(:style="`background-image:url(${'props.trainerId'})`")

</template>

<script setup lang="ts">
import { avatarImg } from '@/libs/utils'
// import { useAppointmentsStore, useAuthStore, useGlobalTrainerStore, useAdminStore } from '@/stores'
import * as stores from '@/stores'
import { computed } from 'vue'

const authStore = stores.useAuthStore()
const aptStore = stores.useAppointmentsStore()
const adminStore = stores.useAdminStore()
const globalTrainerStore = stores.useGlobalTrainerStore()
// avatarImg
const props = defineProps({
	trainerId: { type: String, default: '' },
})
const trainerData = computed(
	() =>
		globalTrainerStore.trainers?.[props.trainerId] ||
		aptStore.aptTrainerContact?.[props.trainerId] ||
		aptStore.trainers?.[props.trainerId] ||
		adminStore?.adminData.trainers?.[props.trainerId] ||
		{},
)

// const randomNumber = Math.floor(Math.random() * 9999)
</script>

<style lang="scss">
.ptp-trainerimage {
	@apply bg-cover w-full h-full rounded-full border bg-center;
}
</style>
