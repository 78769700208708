const admin: any = {
	FitnessStudios: {
		en: 'Fitness Studios',
		de: 'Fitness Studios',
	},
	Users: {
		en: 'Users',
		de: 'Benutzer',
	},
	Trainers: {
		en: 'Trainers',
		de: 'Trainer',
	},
}

const translations: any = { en: {}, de: {} }
const allTranslations: any = { admin }
for (const key in allTranslations) {
	translations['en'][key] = {}
	translations['de'][key] = {}
	for (const tr in allTranslations[key]) {
		translations['en'][key][tr] = allTranslations[key][tr]['en']
		translations['de'][key][tr] = allTranslations[key][tr]['de']
	}
}
export default translations
