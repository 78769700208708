import axios from 'axios'
import { useAuthStore } from '@/stores'
import { waitFor } from '@/libs/utils'

// const ENDPOINT = '/fb'

const instance: any = axios.create({
	// baseURL: import.meta.env.VITE_API_URL + ENDPOINT,
	baseURL: import.meta.env.VITE_API_URL,
	responseType: 'json',
	timeout: 20000,
})

const getToken = async (): Promise<string> => {
	if (!useAuthStore().currentProfile.id) return ''
	let accessToken = ''
	let i = 0
	// todo: refactor
	while (i < 5 && accessToken.length == 0) {
		accessToken = (await useAuthStore().getAccessToken()) || ''
		await waitFor(100 * i ** 2)
		i++
	}
	return accessToken
}

const http = {
	get: async (...args: any) => {
		try {
			console.log('get', args[0])
			const accessToken = await getToken()
			// if (!accessToken) throw new Error('no accessToken')
			instance.defaults.headers.get.accessToken = accessToken
			instance.defaults.headers.get.userId = useAuthStore().currentProfile.id
			const result = await instance.get(...args)
			return result
		} catch (error: any) {
			const message = error.response?.data?.message || error.message
			console.error('http get Error:', args[0], message)
			throw new Error(message)
		}
	},
	getAuth: async (...args: any) => {
		try {
			console.log('getAuth', args[0])
			const accessToken = await getToken()
			if (!accessToken) throw new Error(`no accessToken ${args[0]}`)
			instance.defaults.headers.get.accessToken = accessToken
			instance.defaults.headers.get.userId = useAuthStore().currentProfile.id
			const result = await instance.get(...args)
			return result
		} catch (error: any) {
			const message = error.response?.data?.message || error.message
			console.error('http getAuth Error:', args[0], message)
			throw new Error(message)
		}
	},
	deleteAuth: async (...args: any) => {
		try {
			console.log('deleteAuth', args[0])
			const accessToken = await getToken()
			if (!accessToken) throw new Error(`no accessToken ${args[0]}`)
			instance.defaults.headers.delete.accessToken = accessToken
			instance.defaults.headers.delete.userId = useAuthStore().currentProfile.id
			const result = await instance.delete(...args)
			return result
		} catch (error: any) {
			const message = error.response?.data?.message || error.message
			console.error('http deleteAuth Error:', args[0], message)
			throw new Error(message)
		}
	},
	delete: async (...args: any) => {
		try {
			console.log('delete', args[0])
			const accessToken = await getToken()
			// if (!accessToken) throw new Error(`no accessToken ${args[0]}`)
			instance.defaults.headers.delete.accessToken = accessToken
			instance.defaults.headers.delete.userId = useAuthStore().currentProfile.id
			const result = await instance.delete(...args)
			return result
		} catch (error: any) {
			const message = error.response?.data?.message || error.message
			console.error('http delete Error:', args[0], message)
			throw new Error(message)
		}
	},
	postAuth: async (...args: any) => {
		try {
			console.log('postAuth', args[0])
			const accessToken = await getToken()
			if (!accessToken) throw new Error(`no accessToken ${args[0]}`)
			instance.defaults.headers.post.accessToken = accessToken
			instance.defaults.headers.post.userId = useAuthStore().currentProfile.id
			const result = await instance.post(...args)
			return result
		} catch (error: any) {
			const message = error.response?.data?.message || error.message
			console.error('http postAuth Error:', args[0], message)
			throw new Error(message)
		}
	},
	post: async (...args: any) => {
		try {
			console.log('post', args[0])
			const accessToken = await getToken()
			// if (!accessToken) throw new Error('no accessToken')
			instance.defaults.headers.post.accessToken = accessToken
			instance.defaults.headers.post.userId = useAuthStore().currentProfile.id
			const result = await instance.post(...args)
			return result
		} catch (error: any) {
			const message = error.response?.data?.message || error.message
			console.error('http post Error:', args[0], message)
			throw new Error(message)
		}
	},
}

export default http
