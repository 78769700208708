<template lang="pug">
figure.w-full.h-full
	template(v-if="icon")
		//- img.ptp-locationimage(:src="`/logos/${icon}.svg`" width="96" height="96")
		.ptp-locationimage(:style="`background-image:url(/logos/${icon}.svg)`")
	template(v-else)
		//- .ptp-locationimage(:style="`background-image:url(${authStore.photoURL})`")
		.ptp-locationimage(:style="`background-image:url(${avatarImg(userId)})`")
</template>

<script setup lang="ts">
import { avatarImg } from '@/libs/utils'
import { computed } from 'vue'

const props = defineProps({
	icon: { type: String, default: '' },
	// previewFile: { type: String, default: '' },
})
const icon = computed(() => props.icon)
</script>

<style lang="scss">
.ptp-locationimage {
	// @apply bg-cover w-full h-full rounded-full border-2;
	@apply w-full h-full rounded-full border;
	// transform: scale(1.2);
	background-size: 150%;
	background-position: center;
}
</style>
