import { ref, computed } from 'vue'
import type { Ref } from 'vue'
import { defineStore } from 'pinia'
import type { StoreDefinition } from 'pinia'
import {
	getAuth,
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	// signInAnonymously,
	// sendSignInLinkToEmail,
	sendPasswordResetEmail,
	sendEmailVerification,
} from 'firebase/auth'
import type { UserCredential, User, Auth } from 'firebase/auth'
import { doc, getFirestore, getDoc, setDoc, getDocFromCache, DocumentReference } from 'firebase/firestore'
import type {
	DocumentData,
	// DocumentReference,
} from 'firebase/firestore'
import type { Firestore } from 'firebase/firestore'
import { useStorage, type RemovableRef } from '@vueuse/core'
import http from '@/libs/http'
import { avatarImg } from '@/libs/utils'
// import { useFirestore } from '@vueuse/firebase'
// import { useRouter } from 'vue-router'

import type { Id, UserData, UserId, UserRole } from '@/core/types/types'
import { BehaviorSubject } from 'rxjs'

const getFirebaseError = (error: any) => {
	if (error.code === 'auth/internal-error') return 'CheckLaterAgain'
	if (error.code === 'auth/too-many-requests') return 'CheckLaterAgain'
	if (error.code === 'auth/wrong-password') return 'CheckEmailPassword'
	if (error.code === 'auth/user-not-found') return 'NoUserFound'
	if (error.code === 'auth/network-request-failed') return 'CheckLaterAgain'

	//todo:// if (error.code === 'auth/wrong-password') return 'NettworkError'

	return false
}

export const useAuthStore: StoreDefinition = defineStore('auth', () => {
	//! state

	const authLoading = ref(true)
	const isEmailVerified = ref(false)
	const currentUser = ref({})
	const currentUserData: any = ref({ email: '' })
	const currentProfile: RemovableRef<{
		id: UserId | null
		role: UserRole | null
		email: string
	}> = useStorage('profile', {
		id: null,
		role: null,
		email: '',
	})
	const photoURL = ref(avatarImg(currentProfile.value.id))

	const isAuthenticated = computed(() => isEmailVerified.value && currentProfile.value.id && currentProfile.value.role)

	//! mount
	const db: Firestore = getFirestore()
	const auth: Auth = getAuth()

	//! getters

	const accessToken = computed(() => (auth.currentUser as any)?.stsTokenManager?.accessToken || null)

	// const currentUserData = useFirestore(computed(() => !!currentProfile.value.id && doc(db, 'users', currentProfile.value.id),), [],)

	//! actions

	const getAccessToken = async () => {
		console.log('getAccessToken')
		const token = auth.currentUser?.getIdToken(forceRefreshToken)
		if (forceRefreshToken) forceRefreshToken = false
		return token
	}

	const updateRole = async (): Promise<void> => {
		await auth.currentUser?.getIdToken(true)
		const result: any = await auth.currentUser?.getIdTokenResult(true)
		currentProfile.value.role = result?.claims?.role || null
	}
	const updateImage = async (): Promise<void> => {
		await auth.currentUser?.getIdToken(true)
		const result: any = await auth.currentUser?.getIdTokenResult(true)
		const photo = result?.claims?.picture || ''
		photoURL.value = photo || avatarImg(currentProfile.value.id)
	}

	const init = async () => {
		return await new Promise((resolve: any, e: any) => {
			auth.onAuthStateChanged((user: any) => {
				if (!user) {
					currentUserData.value = { email: '' }
					// currentProfile.value.id = ''
					authLoading.value = false
				} else {
					isEmailVerified.value = user.emailVerified
					updateRole()
					updateImage()
					if (!user.emailVerified) {
						visibilitychange()
						waitForVerification()
					}
					currentProfile.value.email = user.email
					currentProfile.value.id = user.uid
					authLoading.value = false
				}
				console.log('init auth done')
				return resolve()
			})
		})
	}

	const loadUserData = async (forceFromDb = true): Promise<void> => {
		// currentProfile.value.id = auth.currentUser?.uid || ''
		console.log('loadUserData', currentProfile.value.id)
		await updateRole()
		if (!currentProfile.value.id) return
		let docRef: any

		if (currentProfile.value.role == 'trainee') docRef = doc(db, `users`, currentProfile.value.id)
		if (currentProfile.value.role == 'admin') docRef = doc(db, `admins`, currentProfile.value.id)
		if (currentProfile.value.role == 'trainer') docRef = doc(db, `trainers`, currentProfile.value.id)
		if (!docRef) return

		let docSnap: DocumentData
		if (forceFromDb) docSnap = await getDoc(docRef)
		else {
			try {
				docSnap = await getDocFromCache(docRef)
			} catch (error) {
				// error if not in cache, so get from db
				docSnap = await getDoc(docRef)
			}
		}
		await updateImage()
		currentUserData.value = docSnap.data() || {}
		console.log('loadUserData done')
		return
	}

	const updateUserData = async (data: UserData | any) => {
		console.log(data)
		if (!currentProfile.value.id) return
		let changes = 0
		for (const k in data) {
			const key: string = k
			if ((currentUserData.value?.[key] || '') != (data[key] || '')) {
				console.log(key, data[key], currentUserData.value[key])
				changes++
				break
			}
		}
		console.log('changes', changes)
		if (!changes) return
		try {
			const res = await http.postAuth('user/update', data)
			console.log(res)
			await loadUserData()
			return
		} catch (error: any) {
			console.error(error)
			throw new Error(error.message)
		}
		// const docRef: DocumentReference = doc(db, `users`, currentProfile.value.id)
		// try {
		//   const result = await setDoc(docRef, data)
		//   await loadUserData()
		//   console.log(result)
		//   return result
		// } catch (error: any) {
		//   console.error(error)
		//   throw new Error(error.message)
	}
	//! login register methods

	// let unsubscribeAfterVerification: Unsubscribe | null = () => {
	//   return null
	// }
	let forceRefreshToken = false

	const waitForVerification = () => {
		console.log('waitForVerification')
		const unsubscribeAfterVerification = auth.onIdTokenChanged(async (user: any) => {
			isEmailVerified.value = user.emailVerified
			if (user.emailVerified) {
				unsubscribeAfterVerification()
				forceRefreshToken = true
				await loadUserData()
			}
		})
	}

	const visibilitychange = async () => {
		console.log('START visibilitychange')
		// const a = await auth.currentUser?.reload()
		// console.log('dddd reloaded', a);

		const listener = async (event: any) => {
			// await loadUserData()
			await auth.currentUser?.reload()

			console.log('visibilitychange', event.type)
			// console.log(a);
			// console.log('currentUserData.value', currentUserData.value);
			// if (currentUserData.value.email) window.removeEventListener('focus', listener);
		}
		window.addEventListener('focus', listener)
		window.addEventListener('blur', listener)
	}

	const register = async (email: string, password: string) => {
		email = email.toLowerCase().trim()
		try {
			const registerData: UserCredential = await createUserWithEmailAndPassword(auth, email, password)

			if (!auth.currentUser) throw new Error('ErrorInRegistration')
			currentProfile.value.id = (auth.currentUser?.uid as UserId) || null
			await sendEmailVerificationEmail()
			return auth.currentUser || null
		} catch (error: any) {
			const firebaseError = getFirebaseError(error)
			if (firebaseError) throw new Error(firebaseError)
		}
	}
	const sendEmailVerificationEmail = async () => {
		console.log(auth?.currentUser)
		if (auth?.currentUser) {
			await sendEmailVerification(auth?.currentUser)
			console.log('verification email sent')
			// waitForVerification()
		}
	}

	const logIn = async (email: string, password: string) => {
		email = email.toLowerCase().trim()
		try {
			const loginData: UserCredential = await signInWithEmailAndPassword(auth, email, password)
			await loadUserData()
			return true
		} catch (error: any) {
			console.error(error)
			const firebaseError = getFirebaseError(error)
			if (firebaseError) throw new Error(firebaseError)
			throw new Error(error.message)
		}
	}

	const forgotPassword = async (email: string) => {
		email = email.toLowerCase().trim()
		try {
			await sendPasswordResetEmail(auth, email)
			return true
		} catch (error: any) {
			console.error(error)
			const firebaseError = getFirebaseError(error)
			if (firebaseError) throw new Error(firebaseError)
			throw new Error(error.message)
		}
	}

	const logOut = async () => {
		console.log('logOutlogOut')
		try {
			await auth.signOut()
			await loadUserData()
			currentProfile.value.role = null
			currentProfile.value.id = null
			currentProfile.value.email = ''
			await localStorage.clear()
			window.location.href = '/'
			return true
		} catch (error: any) {
			const firebaseError = getFirebaseError(error)
			if (firebaseError) throw new Error(firebaseError)

			throw new Error(error.message)
		}
	}

	return {
		currentUser,
		currentProfile,
		photoURL,
		isAuthenticated,
		isEmailVerified,
		accessToken,
		authLoading,
		currentUserData,
		// doubleCount,
		init,
		loadUserData,
		updateUserData,
		updateImage,
		getAccessToken,
		register,
		sendEmailVerificationEmail,
		logIn,
		forgotPassword,
		// prepareUser,
		logOut,
		// increment,
	}
})
