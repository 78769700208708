import { initializeApp } from 'firebase/app'
// import * as firestore from 'firebase/firestore'
import {
	getFirestore,
	connectFirestoreEmulator,
	CACHE_SIZE_UNLIMITED,
	enableIndexedDbPersistence,
} from 'firebase/firestore'
import { getAuth, connectAuthEmulator, onAuthStateChanged } from 'firebase/auth'
import type { User } from 'firebase/auth'

export class Firebase {
	constructor() {
		this.init()
	}

	async init() {
		// Import the functions you need from the SDKs you need
		// TODO: Add SDKs for Firebase products that you want to use
		// https://firebase.google.com/docs/web/setup#available-libraries

		// Your web app's Firebase configuration
		const firebaseConfig: any = {
			apiKey: 'AIzaSyC_uH0357XkSTJnuDOOb2F_Jh57nVj0VDM',
			authDomain: 'hero-ptp-dev2.firebaseapp.com',
			projectId: 'hero-ptp-dev2',
			storageBucket: 'hero-ptp-dev2.appspot.com',
			messagingSenderId: '662509497754',
		}

		const options = {
			// cacheSizeBytes: CACHE_SIZE_UNLIMITED,
		}

		const firebaseApp = initializeApp(firebaseConfig, options)
		const auth = getAuth(firebaseApp)
		const firestore = getFirestore(firebaseApp)

		//! EMULATOR
		console.log('use EMULATOR:', import.meta.env.VITE_USE_EMULATOR == 'true')
		if (import.meta.env.VITE_USE_EMULATOR == 'true') {
			connectAuthEmulator(auth, 'http://localhost:9099')
			connectFirestoreEmulator(firestore, 'localhost', 9097)
		}
		//! EMULATOR

		//! persistance:
		// await enableIndexedDbPersistence(firestore).catch((err) => {
		// 	console.log(err.message, err.code)
		// 	if (err.code == 'failed-precondition') {
		// 		// Multiple tabs open, persistence can only be enabled
		// 		// in one tab at a a time.
		// 		console.error('fb: too many tabs')
		// 		console.error(err.message)
		// 	} else if (err.code == 'unimplemented') {
		// 		console.error(err.message)
		// 	} else {
		// 		console.error(err.message)
		// 	}
		// })
		// const settings = { timestampsInSnapshots: true }
		// firestore.settings(settings)
		console.log('init firebase')
		return
	}
}
