const meta = {
	layout: 'AppLayoutDefault',
	allowedRoles: 'allUser',
}

const moduleRoute = {
	path: '/trainer',
	name: 'TrainerModule',
	// component: () => TrainerModule,
	component: () => import('../TrainerModule.vue'),
	meta: { ...meta, isParent: true },
	children: [
		{
			path: ':trainerIdent',
			name: 'TrainerHomePage',
			// component: () => TrainerHomePage,
			component: () => import('../views/TrainerHomePage.vue'),
			meta,
		},
	],
}

export default moduleRoute
