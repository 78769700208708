<template lang="pug">

.navbar.bg-base-300.z-50
	.navbar-start
		slot(name="navStart")
			RouterLink.btn.btn-ghost(class="max-w-[100%]" to='/') 
				img(alt='mycoach' src="/johnreed-logo.svg" width='220')

	.navbar-center
		.badge.badge-warning(v-if="isUsingEmulator") EMULATOR
		.badge.badge-warning(v-if="!isOnline") {{$t('offline')}}
		.badge.badge-warning(v-if="authStore.currentProfile?.id && !authStore.isEmailVerified" @click="authStore.sendEmailVerificationEmail()") {{$t('verifyEmail')}}
	.navbar-end.mx-1.gap-1

		CartHeaderBasket
		VSwitchLanguage
		ul.menu.gap-1.flex.items-center.menu-horizontal.p-0.z-50
			template(v-if="authStore.authLoading")
				li(tabindex="0")
					.ptp-spinner
			template(v-else-if="!authStore.currentProfile?.id")
				li(tabindex="0")
					RouterLink(to='/signin') {{$t('login')}}
		VUserLinks
		
</template>

<script setup lang="ts">
import VSwitchLanguage from './VSwitchLanguage.vue'
import VUserLinks from './VUserLinks.vue'
import CartHeaderBasket from '@/modules/cart/components/CartHeaderBasket.vue'

import { ref, computed, onMounted } from 'vue'
import type { Ref } from 'vue'
import { useNetwork } from '@vueuse/core'
import * as stores from '@/stores'
import { makeDashboardRoutes } from '@/router/navigationRoutes'

const authStore = stores.useAuthStore()
const userStore = stores.useUserStore()

const isUsingEmulator = computed(() => import.meta.env.VITE_USE_EMULATOR == 'true')
const { isOnline, offlineAt } = useNetwork()
const dashboardRoutes: Ref<any> = ref(makeDashboardRoutes())
const routes = computed(() => dashboardRoutes.value?.[authStore.currentProfile?.role || ''] || {})
</script>
