const meta = {
	layout: 'AppLayoutUser',
	navGroup: 'account',
	icon: 'ptp_appointment',
	allowedRoles: 'onlySignedIn',
}

const moduleRoute = {
	path: '/dashboard',
	name: 'DashboardModule',
	component: () => import('../DashboardModule.vue'),
	meta: { ...meta, isParent: true },
	children: [
		{
			path: 'appointments',
			name: 'DashboardTraineeAppointments',
			component: () => import('../views/DashboardAppointments.vue'),

			meta,
		},
	],
}

export default moduleRoute
