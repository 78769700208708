<script setup lang="ts">
// import { ref, shallowRef, toRef, watch, computed } from 'vue'
import { computed, toRef } from 'vue'
import { useField } from 'vee-validate'
// import { watchDebounced } from '@vueuse/core'
const emit = defineEmits(['update:modelValue'])

const props = defineProps({
	type: { type: String, default: 'text' },
	value: { type: String, default: '' },
	name: { type: String, required: true },
	label: { type: String, required: true },
	successMessage: { type: String, default: '' },
	placeholder: { type: String, default: '' },
	modelValue: { type: String, default: '' },
	disabled: { type: Boolean, default: false },
})

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name')
const {
	value: inputValue,
	errorMessage,
	handleBlur,
	handleChange,
	meta,
} = useField(name, undefined, {
	initialValue: props.value,
})

const modelValue = computed({
	get() {
		return props.modelValue
	},
	set(nv: string) {
		emit('update:modelValue', nv)
	},
})
// const showMessage = ref(false)
// const showMessage = shallowRef(false)

// const metaPending = computed(() => meta.pending)
// const metaValid = computed(() => meta.valid)

// const showMessageIndicator = computed(() => [
// metaPending.value,
// metaValid.value,
// !!errorMessage.value,
// !!props.successMessage,
// ])

// watchDebounced(showMessageIndicator, (nv: any) => {
// console.log('showMessageIndicator', nv, 'name', name.value)
// showMessage.value = false
// }, { debounce: 30, maxWait: 8000 },)

// watchDebounced(showMessageIndicator, () => {
// showMessage.value = !!errorMessage.value || meta.valid
// console.log('showMessageIndicator', showMessage.value)
// }, { debounce: 700, maxWait: 8000 },)
</script>

<template>
	<div class="ptp-input" :class="{ 'has-error': !!errorMessage, 'has-success': meta.valid }">
		<label v-if="label" :for="name" class="label pb-0 uppercase">
			<span class="label-text">{{ label }}</span>
		</label>
		<input
			class="input input-bordered w-full max-w-xl"
			:name="name"
			:id="name"
			:type="type"
			:value="inputValue"
			:placeholder="placeholder"
			:disabled="disabled"
			@input="handleChange"
			@blur="handleBlur"
		/>
		<span class="help-message" v-if="errorMessage || meta.valid">
			<span v-if="errorMessage || meta.valid">
				{{ errorMessage || successMessage }}
			</span>
		</span>
		<!-- <div class="alert alert-error shadow-lg" v-show="(errorMessage || meta.valid)">
			<div>
				<svg xmlns="http://www.w3.org/2000/svg" class="stroke-current flex-shrink-0 h-6 w-6" fill="none"
					viewBox="0 0 24 24">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
						d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
				</svg>
				<span>Error! Task failed successfully.</span>
			</div>
		</div> -->
	</div>
</template>

<style scoped lang="scss"></style>
