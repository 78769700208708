<template lang="pug">
.dropdown.dropdown-end
  label.btn.btn-ghost.rounded-full.flex.gap-1(tabindex="0")
    i.icon-ptp_world.text-2xl
    span(class="hidden sm:block") {{userStore?.locale||''}}
    span(class="hidden sm:block")
      svg.fill-current(xmlns="http://www.w3.org/2000/svg", width="20", height="20", viewBox="0 0 24 24")
        path(d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z")
  ul.mt-3.p-2.shadow.menu.gap-1.menu-compact.dropdown-content.bg-base-200.rounded-box.w-32(
    tabindex="0"
    onclick="this.parentElement.classList.remove('dropdown-open');document.activeElement.blur()"
    )
    template(v-for="loc in i18.availableLocales")
      li(tabindex="0")
        a.link-hover.uppercase(@click="switchLang(loc)") {{loc}}

</template>

<script setup lang="ts">
import * as stores from '@/stores'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'

const i18 = useI18n()
const userStore = stores.useUserStore()
const switchLang = (lang: string) => {
	i18.locale.value = lang
	userStore.locale = lang
	dayjs.locale(lang)
}
</script>
