const calendar: any = {
	calendar: {
		en: 'calendar',
		de: 'calendar',
	},
}

const translations: any = { en: {}, de: {} }
const allTranslations: any = { calendar }
for (const key in allTranslations) {
	translations['en'][key] = {}
	translations['de'][key] = {}
	for (const tr in allTranslations[key]) {
		translations['en'][key][tr] = allTranslations[key][tr]['en']
		translations['de'][key][tr] = allTranslations[key][tr]['de']
	}
}
export default translations
