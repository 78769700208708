import { ref, computed, watch, type ComputedRef } from 'vue'
import type { Ref } from 'vue'
import { defineStore } from 'pinia'
import type { StoreDefinition } from 'pinia'
import ts from '@/libs/typesense'
import { keyBy } from '@/libs/utils'
import type { TrainerData } from '@/core/types/types'

export const useGlobalTrainerStore: StoreDefinition = defineStore('globalTrainer', () => {
	const trainers: Ref<any> = ref({})
	const trainerList: ComputedRef<TrainerData[]> = computed(() => Object.values(trainers.value))

	const fetchTrainers = async (idList: string[]) => {
		const filterIdList = idList.filter((id: string) => !trainers.value[id])
		const data = await ts.docs('trainers', {
			query_by: 'lastName',
			filter_by: `id:[${filterIdList.join(',')}]`,
		})
		trainers.value = { ...(trainers.value || {}), ...keyBy(data, 'id') }
		return
	}
	const fetchTranierForStudioIds = async (idList: string[]) => {
		const data = await ts.docs('trainers', {
			query_by: 'firstName,lastName,trainerType,',
			filter_by: `locationIds:[${idList.join(',')}]`,
			sort_by: 'rating:desc,ratingCount:desc',
			per_page: 64,
		})
		console.log('fetchTranierForStudioIds', data);

		trainers.value = { ...trainers.value, ...keyBy(data, 'id') }
		return
	}

	return {
		trainers,
		trainerList,
		fetchTrainers,
		fetchTranierForStudioIds,
	}
})
