import { ref, computed, watch } from 'vue'
import type { Ref, ComputedRef } from 'vue'
import { defineStore } from 'pinia'
import type { StoreDefinition } from 'pinia'
import { geoDistance, keyBy } from '@/libs/utils'
import ts from '@/libs/typesense'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores'

// import { useUserStore } from '@/stores'
export const useLocationStore: StoreDefinition = defineStore('location', () => {
	const userStore = useUserStore()
	const router = useRouter()

	//!locations
	const studios: Ref<any> = ref({})
	// const studioList = ref([])
	const studioList = computed(() => Object.values(studios.value))
	const studioIdsList: any = computed(() => Object.keys(studios.value))
	const studiosByName: any = computed(() => keyBy(studioList.value, 'name'))
	const locationId: ComputedRef<any> = computed(
		() => router.currentRoute?.value?.query?.id || 'jrYBDzHJzbURpONHBr5NN3eY3fsS',
	)

	const currentLocation = computed(() => studios.value?.[locationId.value] || {})

	const studioDistances = computed(() => {
		const result: any = {}
		// const cc = currentLocation.value.coordinates
		const uc = userStore.userCoords.coords
		for (const id in studios.value) {
			const c = studios.value[id].coordinates
			console.log(c)
			if (c && uc) result[id] = geoDistance(uc[1], uc[0], c[0], c[1])
		}
		return result
	})

	//!trainers
	// const trainerList: Ref<any[]> = ref([])
	// const trainerDistances = computed(() => {
	//   const result: any = {}
	//   for (const t of trainerList.value) {
	//     const doc: any = t
	//     // const doc: any = (t as any).document
	//     result[doc.id] = 99999
	//     for (const sid of doc.locationIds) {
	//       if (result[doc.id] > studioDistances.value[sid])
	//         result[doc.id] = studioDistances.value[sid]
	//     }
	//   }
	//   return result
	// })

	// const trainerListForLocation = computed(() =>
	//   trainerList.value.filter((el: any) =>
	//     el.locationIds.includes(locationId.value),
	//   ),
	// )

	//!methods
	const fetchNearStudioList = async (coords: any = [16.3667, 48.2]) => {
		// vienna for now
		const [lng, lat] = coords
		const data = await ts.docs('locations', {
			query_by: 'city',
			filter_by: `coordinates:(${lat},${lng}, 80 km)`,
			sort_by: `coordinates(${lat},${lng}):asc`,
			per_page: 64,
		})
		studios.value = { ...studios.value, ...keyBy(data, 'id') }
		return
	}

	const fetchLocations = async (idList: string[]) => {
		const filterIdList = idList.filter((id: string) => !studios.value[id])
		const data = await ts.docs('locations', {
			query_by: 'name',
			filter_by: `id:[${filterIdList.join(',')}]`,
		})
		studios.value = { ...studios.value, ...keyBy(data, 'id') }
		return
	}

	const fetchAllStores = async () => {
		const data = await ts.docs('locations', {
			query_by: 'name',
			per_page: 250,
		})
		studios.value = { ...studios.value, ...keyBy(data, 'id') }
		return
	}

	const selectId = (id: string) => {
		router.replace({ query: { id } })
	}

	// const studio = ref({})
	// const fetchStudio = async (_locationId: string) => {
	//   try {
	//     const data = await ts.getDoc('locations', _locationId)
	//     studio.value = data
	//     return data
	//   } catch (error: any) {
	//     console.log(error.message)
	//     studio.value = {}
	//   }
	// }

	return {
		locationId,
		currentLocation,
		// studio,
		studios,
		studioList,
		studioIdsList,

		studiosByName,

		studioDistances,
		// trainerList,
		// trainerListForLocation,
		// trainerDistances,
		selectId,
		// fetchStudio,
		fetchLocations,
		fetchNearStudioList,
		fetchAllStores,
	}
})
