import type { RouteRecordRaw } from 'vue-router'
import locationRouter from '@/modules/Location'

// import * as account from '@/modules/account'
// import * as calendar from '@/modules/calendar'
// import * as cart from '@/modules/cart'
// import * as admin from '@/modules/dashboard/admin'
// import * as dashboardTrainer from '@/modules/dashboard/trainer'
// import * as user from '@/modules/dashboard/user'
// import * as home from '@/modules/home'
// import * as payment from '@/modules/payment'
// import * as trainer from '@/modules/trainer'

// account.router,
// calendar.router,
// cart.router,
// admin.router,
// dashboardTrainer.router,
// user.router,
// home.router,
// payment.router,
// trainer.router,

// console.log('account.router', account.router);
// console.log('home.router', home.router);

const routes: Array<RouteRecordRaw> = [
	{
		path: '/location/search',
		name: 'Home',
		component: () => import('@/views/HomeView.vue'),
		meta: { layout: 'AppLayoutDefault', allowedRoles: 'allUser', navGroup: '' },
	},

	// {
	//   path: '/about',
	//   name: 'About',
	//   component: () => import('@/views/AboutView.vue'),
	//   meta: { layout: 'AppLayoutDefault', allowedRoles: 'allUser', navGroup: '' },
	// },

	locationRouter,

	//?
	{
		path: '/:pathMatch(.*)*',
		name: '404',
		component: () => import('@/views/SiteNotFound.vue'),
		meta: { layout: 'AppLayoutDefault', allowedRoles: 'allUser', navGroup: '' },
	},
]

console.log('routes', routes)

export default routes
