import routes from './routes'
import { roles, type RoleGroup } from './roles'
import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import { useAuthStore } from '@/stores'
// import { useStorage } from '@vueuse/core'
// import { getAuth } from 'firebase/auth'
import { waitFor } from '@/libs/utils'

console.log('all routes ', routes)

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	// history: createWebHashHistory(import.meta.env.BASE_URL),
	routes,
})

router.beforeEach(async (to: any, from: any, next) => {
	try {
		const authStore = useAuthStore()
		const role = authStore.currentProfile.role || null
		const allowedRoles: RoleGroup = to.meta.allowedRoles || 'allUser' || null
		console.log(allowedRoles, role)
		if (['allUser', 'notSignedIn'].includes(allowedRoles)) return next()
		else {
			const isAuthenticated = authStore.isAuthenticated || false
			const isAllowed = (isAuthenticated && roles[allowedRoles]?.includes(role)) || false
			console.log({ isAuthenticated, isAllowed, roles, allowedRoles, role })
			// await waitFor(20)
			if (isAllowed) return next()
			else {
				const redirect = to.meta.redirect || '/signin'
				return next(redirect)
			}
		}
	} catch (error: any) {
		console.log('routererror')
		console.log(error.message)
		console.error(error)
	}
})

export default router
