import { ref, watch, computed, type Ref, initCustomFormatter } from 'vue'
import { defineStore } from 'pinia'
import type { StoreDefinition } from 'pinia'
import { useGeolocation } from '@vueuse/core'
import { useStorage } from '@vueuse/core'
import http from '@/libs/http'
import { geoDistance } from '@/libs/utils'
import { doc, collection, getFirestore, getDoc, getDocs, query, where } from 'firebase/firestore'
import type { DocumentData } from 'firebase/firestore'
import { now } from '@/libs/utils'

export const useAdminStore: StoreDefinition = defineStore('admin', () => {
	const switchUserRole = async (uid: string, role: string) => {
		try {
			return await http.postAuth(`/user/admin/switchrole`, { uid, role })
		} catch (error: any) {
			console.error(error)
			throw new Error(error.message)
		}
	}

	const createUser = async (creds: any, data: any) => {
		try {
			return await http.postAuth(`/user/admin/user/create`, { creds, data })
		} catch (error: any) {
			console.error(error)
			throw new Error(error.message)
		}
	}

	const updateUser = async (uid: string, role: string, data: any) => {
		try {
			return await http.postAuth(`/user/admin/user/update`, { uid, role, data })
		} catch (error: any) {
			console.error(error)
			throw new Error(error.message)
		}
	}

	const deleteUser = async (uid: string) => {
		try {
			return await http.deleteAuth(`/user/admin/user/delete/${uid}`)
		} catch (error: any) {
			console.error(error)
			throw new Error(error.message)
		}
	}

	//!!
	//!!
	//!!

	const adminData = useStorage('aData', {
		users: {},
		trainers: {},
		appointments: {},
	})

	//! users
	// const allUsersList = computed(() => Object.values(allUsers.value))
	const filteredUser = computed(() => {
		const result: any = {}
		const users: any = adminData.value.users
		for (const uid in users) {
			if (users[uid].role != 'trainer') {
				result[uid] = users[uid]
			}
		}
		return result
	})
	const modified = useStorage(`modifiedAd`, { trainers: 0, users: 0, appointments: 0 })

	const fetchUsers = async () => {
		console.log('fetchUsers')
		const result: any = {}
		const db = getFirestore()
		const q: any = query(collection(db, 'users'), where('modified', '>', modified.value.users))
		const querySnapshot = await getDocs(q)
		querySnapshot.forEach((doc: DocumentData) => {
			// doc.data() is never undefined for query doc snapshots
			console.log('doc.id', doc.id, ' => ', doc.data())
			result[doc.id] = doc.data()
		})

		const q2: any = query(collection(db, 'admins'), where('modified', '>', modified.value.users))
		const querySnapshot2 = await getDocs(q2)
		querySnapshot2.forEach((doc: DocumentData) => {
			// doc.data() is never undefined for query doc snapshots
			console.log('doc.id', doc.id, ' => ', doc.data())
			result[doc.id] = doc.data()
		})
		adminData.value.users = { ...adminData.value.users, ...result }

		modified.value.users = now() - 10
		return result
	}

	const fetchUserById = async (uid: string) => {
		console.log('fetchUserById', uid)
		const db = getFirestore()
		// const q: any = query(doc(db, 'users'))
		const docRef = doc(db, `users`, uid)
		const d: DocumentData = await getDoc(docRef)
		const user = d.data()
		adminData.value.users = { ...adminData.value.users, ...user }
		return d.data()
		// const querySnapshot = await getDocs(q)
		// querySnapshot.forEach((doc: DocumentData) => {
		// 	// doc.data() is never undefined for query doc snapshots
		// 	console.log('doc.id', doc.id, ' => ', doc.data())
		// 	result[doc.id] = doc.data()
		// })
	}

	//! trainers
	// const allTrainers = useStorage('allTrainers', {})
	const fetchTrainers = async () => {
		// const collectionRef = doc(db, "users", "UFNIqdJFlb8Jr2KmjrXx");
		const result: any = {}
		const db = getFirestore()
		const q: any = query(collection(db, 'trainers'), where('modified', '>', modified.value.trainers))

		const querySnapshot = await getDocs(q)
		querySnapshot.forEach((doc: DocumentData) => {
			// doc.data() is never undefined for query doc snapshots
			console.log('doc.id', doc.id, ' => ', doc.data())
			result[doc.id] = doc.data()
		})
		modified.value.trainers = now() - 10
		console.log('trainer fetch', Object.keys(result).length)
		adminData.value.trainers = { ...adminData.value.trainers, ...result }
		return result
	}

	//! trainers
	// const allAppointments = useStorage('allAppointments', {})

	const fetchAppointmentsByUid = async (uid = '') => {
		console.log('fetchAppointmentsByUid')
		if (!uid) return
		const result: any = {}
		const db = getFirestore()
		const q: any = query(
			collection(db, 'appointments'),
			where('initiatorId', '==', uid),
			// where('trainerId', '==', uid),
			// where('modified', '>', modified.value.appointments),
		)
		const querySnapshot = await getDocs(q)
		querySnapshot.forEach((doc: DocumentData) => {
			// doc.data() is never undefined for query doc snapshots
			console.log('doc.id', doc.id, ' => ', doc.data())
			result[doc.id] = doc.data()
		})
		modified.value.appointments = now() - 10
		console.log('userss', Object.keys(result).length)
		adminData.value.appointments = { ...adminData.value.appointments, ...result }
		return result
	}

	return {
		switchUserRole,
		createUser,
		updateUser,
		deleteUser,
		// allUsers,
		// allTrainers,
		// allAppointments,
		adminData,
		filteredUser,
		fetchUsers,
		fetchUserById,
		fetchTrainers,
		fetchAppointmentsByUid,
		// count,
	}
})
