export type Role = 'admin' | 'trainer' | 'trainee' | 'developer' | null
export type RoleGroup =
	| 'allUser'
	| 'notSignedIn'
	| 'onlySignedIn'
	| 'onlyAdmins'
	| 'onlyTrainers'
	| 'onlyUsers'
	| 'onlyDeveloper'
export type Roles = {
	allUser: Role[]
	notSignedIn: Role[]
	onlySignedIn: Role[]
	onlyAdmins: Role[]
	onlyTrainers: Role[]
	onlyUsers: Role[]
	onlyDeveloper: Role[]
}

// const roles: Roles = {
export const roles: Roles = {
	allUser: ['admin', 'trainer', 'developer', 'trainee'],
	notSignedIn: [null],
	onlySignedIn: ['admin', 'trainer', 'developer', 'trainee'],
	onlyAdmins: ['admin', 'developer'],
	onlyTrainers: ['developer', 'trainer'],
	onlyUsers: ['admin', 'developer', 'trainee'],
	onlyDeveloper: ['developer'],
}
